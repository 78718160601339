<template>
    <!--  BEGIN SIDEBAR -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <!-- <div class="shadow-bottom"></div> -->
            <perfect-scrollbar
                v-if="menu"
                class="list-unstyled menu-categories"
                :class="[$store.state.menu_style == 'collapsible-vertical' ? '' : 'ps-v']"
                tag="ul"
                :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }"
            >
                <router-link to="/">
                    <img
                        style="padding: 10px"
                        :class="[$store.state.menu_style == 'collapsible-vertical' ? `${store.state.is_show_sidebar ? 'd-none' : 'd-block'}` : 'd-none']"
                        src="@/assets/images/Logo13.svg"
                        class="navbar-logo navbar-logo-n"
                        alt="logo"
                    />
                    <img
                        :class="[$store.state.menu_style == 'collapsible-vertical' ? `${store.state.is_show_sidebar ? 'd-block' : 'd-none'}` : 'd-block']"
                        src="@/assets/images/Logo12.svg"
                        class="navbar-logo navbar-logo-n"
                        alt="logo"
                    />
                </router-link>
                <li class="menu" v-for="m in menu" :key="m.ID">
                    
                    <template v-if="userData && userData.agency.agency_level == 0">
                        <a
                            v-if="m.children && m.children.length > 0"
                            class="dropdown-toggle"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#menu_' + m.ID"
                            aria-controls="dashboard"
                            aria-expanded="false"
                        >
                            <div class="">
                                <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                                <span>{{ $t(m.title) }}</span>
                            </div>
                            <div>
                                <vue-feather v-if="m.children && m.children.length > 0" type="chevron-right"></vue-feather>
                            </div>
                        </a>
                        <router-link :to="{ name: m.route }" v-else class="dropdown-toggle" aria-controls="dashboard" aria-expanded="false">
                            <div class="">
                                <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                                <span>{{ $t(m.title) }}</span>
                            </div>
                            <div>
                                <vue-feather v-if="m.children && m.children.length > 0" type="chevron-right"></vue-feather>
                            </div>
                        </router-link>
                        <ul v-if="m.children && m.children.length > 0" :id="'menu_' + m.ID" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                            <li v-for="mc in m.children" :key="mc.ID">
                                <router-link v-if="checkRoute(mc.route)" :to="{ name: mc.route }" @click="toggleMobileMenu" @click.right="Confirm(mc.route, mc.title)">
                                    {{ $t(mc.title) }}
                                </router-link>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <!--  m.title : Agency New  -->
                        <template v-if="m.ID == 13">
                            <template v-if="m.children.length > 0">
                                <router-link v-for="mc in m.children" :key="mc.ID" :to="{ name: mc.route }" class="dropdown-toggle" aria-expanded="false">
                                    <div class="">
                                        <Icon v-if="mc.icon != null" :icon="mc.icon" width="18" height="18" />
                                        <span>{{ $t(mc.title) }}</span>
                                    </div>
                                </router-link>
                            </template>
                        </template>
                        <template v-else>
                            <a
                                v-if="m.children && m.children.length > 0"
                                class="dropdown-toggle"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#menu_' + m.ID"
                                aria-controls="dashboard"
                                aria-expanded="false"
                            >
                                <div class="">
                                    <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                                    <span>{{ $t(m.title) }}</span>
                                </div>
                                <div>
                                    <vue-feather v-if="m.children && m.children.length > 0" type="chevron-right"></vue-feather>
                                </div>
                            </a>
                            <router-link :to="{ name: m.route }" v-else class="dropdown-toggle" aria-controls="dashboard" aria-expanded="false">
                                <div class="">
                                    <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                                    <span>{{ $t(m.title) }}</span>
                                </div>
                                <div>
                                    <vue-feather v-if="m.children && m.children.length > 0" type="chevron-right"></vue-feather>
                                </div>
                            </router-link>
                            <ul v-if="m.children && m.children.length > 0" :id="'menu_' + m.ID" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                                <li v-for="mc in m.children" :key="mc.ID">
                                    <router-link v-if="checkRoute(mc.route)" :to="{ name: mc.route }" @click="toggleMobileMenu" @click.right="Confirm(mc.route, mc.title)">
                                        {{ $t(mc.title) }}
                                    </router-link>
                                </li>
                            </ul>
                        </template>
                    </template>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
    <!--  Modal Confim  -->
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" ref="modal_confirm" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h5 class="fw-bold text-black">{{ $t('Do you want to add it to the extensions?') }}</h5>
                    <div class="d-flex justify-content-center mt-4">
                        <button type="button" class="btn btn-primary me-3" @click="addExtension">{{ $t('Yes') }}</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" data-bs-dismiss="modal">{{ $t('No') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Toast -->
    <div class="toast-container position-fixed end-0 p-3">
        <div ref="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000">
            <div class="toast-header" :style="{ 'background-color': bg }">
                <strong class="me-auto">{{ titleToast }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body bg-white">
                <p class="fw-bold">{{ contentToast }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    const store = useStore();
    const router = useRouter();

    const menu_collapse = ref('dashboard');
    const menu = computed(() => {
        return store.getters.getMenu;
    });
    onMounted(() => {
        if (localStorage.userData) {
            store.dispatch('fetchMenu', router);
            var u = JSON.parse(localStorage.userData);
            try {
                //window.fcWidget.setExternalId(u.username);
                window.fcWidget.user.setFirstName(u.fullName);
                window.fcWidget.user.setEmail(u.email);
            } catch (e) {}
        }
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const checkRoute = (name_) => {
        try {
            let l = router.resolve({ name: name_ });
            return true;
        } catch (e) {
            return false;
        }
    };

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>

<script>
    import { Modal, Toast } from 'bootstrap';
    import { Icon } from '@iconify/vue';
    export default {
        components: {
            Modal,
            Toast,
            Icon,
        },
        mounted() {
            this.confirmModal = new Modal(this.$refs.modal_confirm);
            this.toast = new Toast(this.$refs.liveToast);
            this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
        },
        data() {
            
            return {
                confirmModal: null,
                toast: null,
                path: null,
                name: null,
                titleToast: null,
                contentToast: null,
                bg: null,
                userData: null,
            };
        },
      
        unmounted() {
            this.confirmModal.hide();
            this.toast.hide();
        },
        methods: {
            Confirm(route, title) {
                this.confirmModal.show();
                this.path = route;
                this.name = title;
                console.log({ path: this.path, name: this.name });
            },
            async addExtension() {
                var arr = this.$store.getters.getExtensions;
                var check = arr.filter((item) => item.name == this.name);
                if (check.length == 0) {
                    await this.$store.dispatch('fetchExtensions', { path: this.path, name: this.name });
                    this.titleToast = 'Success!';
                    this.contentToast = 'New extension successfully added!';
                    this.bg = '#83C75D';
                } else if (check.length == 1) {
                    this.titleToast = 'Warning!';
                    this.contentToast = 'Extension already exists!';
                    this.bg = '#F9CC76';
                }
                this.confirmModal.hide();
                this.toast.show();
            },
        },
    };
</script>

<style scoped>
    .navbar-logo-n {
        padding: 15px;
    }
</style>
