<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">Copyright © 2021 <a target="_blank" href="https://gigago.vn">GIGAGO</a>, All rights reserved.</p>
        </div>
        <div class="footer-section f-section-2">
            <p class="">
                
            </p>
        </div>
    </div>
    <!-- END FOOTER -->
</template>
