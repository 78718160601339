import { createStore } from 'vuex';
import { $themeConfig } from '@themeConfig';
import axios from 'axios';

axios.interceptors.request.use(function (config) {
    const token = localStorage.token;
    config.headers.apiKey = token;
    if (config[0] == undefined) {
        document.getElementById('loading_id').style.display = 'block';
    }
    return config;
});
axios.interceptors.response.use(
    (response) => {
        document.getElementById('loading_id').style.display = 'none';
        return response;
    },
    (error) => {
        document.getElementById('loading_id').style.display = 'none';
        return Promise.reject(error);
    }
);

export default {
    state: {
        isLoading: false,
        beforePage: null,
        menu: null,
        groups: null,
        groupPermission: null,
        agencies: null,
        agencies1: null,
        cities: null,
        districts: null,
        wards: null,
        users: null,
        uni_users: null,
        userGroups: null,
        mds: null,
        countries: null,
        goapp_countries: null,
        providers: null,
        email_templates: null,
        country_tags: null,
        careers: null,
        confirmPassWord: null,
        role_users: null,
        getstart: null,
        tips: null,
        infor_product: null,
        extensions: [],
        categories: null,
        warehouses: null,
        assign_products: null,
        assign_agencies: null,
        export_plan_agency: null,
        agency_level_discounts: null,
        agency_level_discounts_extra: null,
        rule_flags: null,
    },

    mutations: {
        SET_RULE_FLAGS(state, payload) {
            state.rule_flags = payload;
        },
        SET_USER_INFO(state, users) {
            localStorage.userData = JSON.stringify(users);
            localStorage.token = users.apiKey;
        },
        SET_CONFIRM_PASSWORD(state, payload) {
            state.confirmPassWord = payload;
        },
        SET_ROLE_USER(state, payload) {
            state.role_users = payload;
        },
        setBeforePage(state, payload) {
            state.beforePage = payload;
        },
        SET_MENU(state, payload) {
            state.menu = payload;
        },
        SET_GROUPS(state, payload) {
            state.groups = payload;
        },
        SET_STATE(state, payload) {
            state.isLoading = payload;
        },
        SET_GROUP_PERMISSION(state, payload) {
            state.groupPermission = payload;
        },
        SET_AGENCIES(state, payload) {
            state.agencies = payload;
        },
        SET_PLAN_AGENCY(state, payload) {
            state.export_plan_agency = payload;
        },
        SET_AGENCIES1(state, payload) {
            state.agencies1 = payload;
        },
        SET_COUNTRIES(state, payload) {
            state.countries = payload;
        },
        SET_GOAPP_COUNTRIES(state, payload) {
            state.goapp_countries = payload;
        },
        SET_CITIES(state, payload) {
            state.cities = payload;
        },
        SET_DISTRICTS(state, payload) {
            state.districts = payload;
        },
        SET_WARDS(state, payload) {
            state.wards = payload;
        },
        SET_USERS(state, payload) {
            state.users = payload;
        },
        SET_UNI_USERS(state, payload) {
            state.uni_users = payload;
        },
        SET_USER_GROUPS(state, payload) {
            state.userGroups = payload;
        },
        SET_MDS(state, payload) {
            state.mds = payload;
        },
        SET_PROVIDERS(state, payload) {
            state.providers = payload;
        },
        SET_EMAIL_TEMPLATES(state, payload) {
            state.email_templates = payload;
        },

        SET_COUNTRY_TAGS(state, payload) {
            state.country_tags = payload;
        },
        SET_CAREERS(state, payload) {
            state.careers = payload;
        },
        SET_GETSTART(state, payload) {
            state.getstart = payload;
        },
        SET_TIPS(state, payload) {
            state.tips = payload;
        },
        SET_INFOR_PRODUCT(state, payload) {
            state.infor_product = payload;
        },
        SET_EXTENSIONS(state, payload) {
            state.extensions.push(payload);
        },
        SET_CATEGORIES(state, payload) {
            state.categories = payload;
        },

        SET_WAREHOUSES(state, payload) {
            state.warehouses = payload;
        },
        SET_ASSIGN_PRODUCT(state, payload) {
            state.assign_products = payload;
        },

        SET_ASSIGN_AGENT(state, payload) {
            state.assign_agencies = payload;
        },
        SET_AGENCY_LEVEL_DISCOUNT(state, payload) {
            state.agency_level_discounts = payload;
        },
        SET_AGENCY_LEVEL_DISCOUNT_EXTRA(state, payload) {
            state.agency_level_discounts_extra = payload;
        },
    },
    getters: {
        getRuleFlags(state) {
            return state.rule_flags;
        },
        getUserInfo(state) {
            return localStorage.userData ? JSON.parse(localStorage.userData) : null;
        },
        getConfirmPass(state) {
            return state.confirmPassWord;
        },
        getRoleUser(state) {
            return state.role_users;
        },
        getMds(state) {
            return state.mds;
        },
        getProviders(state) {
            return state.providers;
        },
        getEmailTemplates(state) {
            return state.email_templates;
        },

        getCountryTags(state) {
            return state.country_tags;
        },
        getToken(state) {
            return localStorage.token;
        },
        getMenu(state) {
            return state.menu;
        },
        getGroups(state) {
            return state.groups;
        },
        getGroupPermission(state) {
            return state.groupPermission;
        },
        getState(state) {
            return state.isLoading;
        },
        getAgencies(state) {
            return state.agencies;
        },
        getExportPlanAgency(state) {
            return state.export_plan_agency;
        },
        getAgencies1(state) {
            return state.agencies1;
        },
        getCoutries(state) {
            return state.countries;
        },
        getGoAppCountries(state) {
            return state.goapp_countries;
        },
        getCities(state) {
            return state.cities;
        },
        getDistricts(state) {
            return state.districts;
        },
        getWards(state) {
            return state.wards;
        },
        getUniUsers(state) {
            return state.uni_users;
        },
        getUsers(state) {
            return state.users;
        },
        getUserGroups(state) {
            return state.userGroups;
        },
        getCareers(state) {
            return state.careers;
        },
        getStart(state) {
            return state.getstart;
        },
        getTips(state) {
            return state.tips;
        },
        getInforProduct(state) {
            return state.infor_product;
        },

        getWarehouses(state) {
            return state.warehouses;
        },
        getExtensions(state) {
            return state.extensions;
        },
        getCategories(state) {
            return state.categories;
        },
        getWarehouse(state) {
            return state.warehouses;
        },

        getAssignProducts(state) {
            return state.assign_products;
        },
        getAssignAgencies(state) {
            return state.assign_agencies;
        },
        getAgencyLevelDiscounts(state) {
            return state.agency_level_discounts;
        },
        getAgencyLevelDiscountExtra(state) {
            return state.agency_level_discounts_extra;
        },
    },
    actions: {
        //list categories
        async fetchCategories({ commit }) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/md_categories', { columnFilters: {}, sort: [], page: 1, pageSize: 171 });
                commit('SET_CATEGORIES', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        //list agency level discount
        async fetchAgencyLevelDiscounts({ commit }) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/discount_agency_levels', { columnFilters: {}, sort: [], page: 1, pageSize: 50 });
                commit('SET_AGENCY_LEVEL_DISCOUNT', data.data.result);
                commit('SET_AGENCY_LEVEL_DISCOUNT_EXTRA', data.data.extra);
            } catch (error) {
                console.log(error);
            }
        },

        //list rule flags
        async fetchRuleFlags({ commit }) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/rule_flag', { columnFilters: {}, sort: [], page: 1, pageSize: 50 });
                commit('SET_RULE_FLAGS', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        //list extensions
        fetchExtensions({ commit }, params) {
            commit('SET_EXTENSIONS', params);
        },
        //infor_product
        async fetchInforProduct({ commit }, params) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/products', { columnFilters: { code: params }, sort: [], page: 1, pageSize: 50 });
                commit('SET_INFOR_PRODUCT', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        //assign_product: form agent and agent discount.
        async fetchAssignProduct({ commit }, params) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/assign_products', { columnFilters: { code: params }, sort: [], page: 1, pageSize: 50000 });
                commit('SET_ASSIGN_PRODUCT', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        //assign_agent: form agent and agent discount.
        async fetchAssignAgent({ commit }, params) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/assign_agencies', { columnFilters: { code: params }, sort: [], page: 1, pageSize: 50000 });
                commit('SET_ASSIGN_AGENT', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        //getstart local
        fetchGetStart({ commit }, params) {
            commit('SET_GETSTART', params);
        },
        //tips
        async fetchTips({ commit }, params) {
            try {
                const data = await axios.post($themeConfig.url + 'getMasterData/helpdesk_infos', { columnFilters: { type_id: params }, sort: [], page: 1, pageSize: 50 });
                commit('SET_TIPS', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        //change getstart
        async changeGetStart({ commit }, params) {
            axios.put($themeConfig.url + 'masterData/user_getstart', { id: params });
        },
        async fetchCountries({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/md_countries', { columnFilters: {}, sort: [], page: 1, pageSize: 100 })
                .then((response) => {
                    commit('SET_COUNTRIES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchGoAppCountries({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/goap_md_countries', { columnFilters: {}, sort: [], page: 1, pageSize: 600 })
                .then((response) => {
                    commit('SET_GOAPP_COUNTRIES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        //address
        async fetchCities({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/md_cities', { columnFilters: {}, sort: [], page: 1, pageSize: 200 })
                .then((response) => {
                    commit('SET_CITIES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchDistricts({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/md_districts', { columnFilters: { city_id: params }, sort: [], page: 1, pageSize: 1000 })
                .then((response) => {
                    commit('SET_DISTRICTS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchWards({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/md_wards', { columnFilters: { district_id: params }, sort: [], page: 1, pageSize: 10000 })
                .then((response) => {
                    commit('SET_WARDS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        //Group MGMT
        async fetchUserInfo({ commit }, body) {
            try {
                const data = await axios.post($themeConfig.url + 'login', body);
                commit('SET_USER_INFO', data.data.result);
                commit('SET_GETSTART', data.data.result.getstart);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMenu({ commit }, router) {
            try {
                const data = await axios.get($themeConfig.url + 'menu');
                commit('SET_MENU', data.data.result);
            } catch (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'login' });
                }
                console.log(error);
            }
        },
        async fetchGroup({ commit }, params) {
            axios
                .post($themeConfig.url + 'getGroup', params)
                .then((response) => {
                    commit('SET_GROUPS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchUserGroups({ commit }, params) {
            axios
                .get($themeConfig.url + 'userGroup/' + params)
                .then((response) => {
                    commit('SET_USER_GROUPS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchUserRole({ commit }, params) {
            axios
                .get($themeConfig.url + 'userGroup/' + params)
                .then((response) => {
                    commit('SET_ROLE_USER', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async groupPermission({ commit }, params) {
            axios
                .get($themeConfig.url + 'groupPermission/' + params)
                .then((response) => {
                    var p = response.data.result;
                    p.forEach((i) => {
                        i.check = i.function_id != null;
                    });
                    commit('SET_GROUP_PERMISSION', p);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async updatePermission({ commit }, p) {
            axios
                .put($themeConfig.url + 'groupPermission/' + p.params.id, p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.permisModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật quyền thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật quyền: ') + ': ' + error.response.data.message, 'error');
                });
        },
        async fetchUserInfoConfirm({ commit }, body) {
            axios
                .post($themeConfig.url + 'login', body)
                .then((response) => {
                    commit('SET_CONFIRM_PASSWORD', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                    commit('SET_CONFIRM_PASSWORD', 'error');
                });
        },
        async updatePassword({ commit }, p) {
            axios
                .put($themeConfig.url + 'updatePassword', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.passModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật mật khẩu thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật mật khẩu') + ': ' + error.response.data.message, 'error');
                });
        },
        async updateUserGroup({ commit }, p) {
            axios
                .put($themeConfig.url + 'userGroup/' + p.params.id, { id: p.params.id, groups: p.params.groups })
                .then((response) => {
                    p.vm?.loadItems();
                    p.vm?.groupModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật quyền cho người dùng thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật quyền cho người dùng') + ': ' + error.response.data.message, 'error');
                });
        },
        async updateUserGroupAgecyNew({ commit }, p) {
            axios
                .put($themeConfig.url + 'userGroup/' + p.params.id, { id: p.params.id, groups: p.params.groups })
                .then((response) => {
                    p.vm.$msg(p.vm.$t('Cập nhật quyền cho người dùng thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật quyền cho người dùng') + ': ' + error.response.data.message, 'error');
                });
        },
        async fecthCareers({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/md_career', p)
                .then((response) => {
                    commit('SET_CAREERS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async newGroup({ commit }, p) {
            axios
                .post($themeConfig.url + 'group', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới nhóm người dùng'), 'error');
                });
        },
        async newEsimSingle({ commit }, p) {
            axios
                .post($themeConfig.url + 'masterData/esim_single', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.newModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới') + ': ' + error.response.data.message, 'error');
                });
        },
        async editGroup({ commit }, p) {
            axios
                .put($themeConfig.url + 'group', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật nhóm người dùng'), 'error');
                });
        },

        async deleteGroup({ commit }, p) {
            axios
                .delete($themeConfig.url + 'group/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Xoá nhóm thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi xoá nhóm người dùng') + ': ' + error.response.data.message, 'error');
                });
        },

        async fetchAgencies({ commit }, params) {
            await axios
                .post($themeConfig.url + 'getMasterData/agencies', { columnFilters: {}, sort: [], page: 1, pageSize: 10000 })
                .then((response) => {
                    commit('SET_AGENCIES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchAgency({ commit }, params) {
            commit('SET_AGENCIES1', null);
            await axios
                .post($themeConfig.url + 'getMasterData/agency', params)
                .then((response) => {
                    var x = response.data.result;
                    x.forEach((i) => {
                        if (i.meta != null && i.meta != '') {
                            i.meta = JSON.parse(i.meta);
                        }
                    });
                    console.log('...............fetchAgency', x);
                    commit('SET_AGENCIES1', x);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchAgencyNew({ commit }, p) {
            // commit('SET_AGENCIES1', null);
            await axios
                .post($themeConfig.url + 'getMasterData/agency', p.params)
                .then((response) => {
                    var x = response.data.result;
                    x.forEach((i) => {
                        if (i.meta != null && i.meta != '') {
                            i.meta = JSON.parse(i.meta);
                        }
                    });
                    console.log('...............fetchAgency', x);
                    p.vm.totalRecords = response.data.totalRecords;
                    commit('SET_AGENCIES1', x);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchAgencyGrant({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/agency-grant', params)
                .then((response) => {
                    response.data.result.forEach((i) => {
                        if (i.meta != null && i.meta != '') {
                            i.meta = JSON.parse(i.meta);
                        }
                    });
                    commit('SET_AGENCIES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchExportPlanAgency({ commit }, params) {
            await axios
                .post($themeConfig.url + 'export_plan_agency', params)
                .then((response) => {
                    console.log('........fetchExportPlanAgency', response);
                    commit('SET_PLAN_AGENCY', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async newAgency({ commit }, p) {
            axios
                .post($themeConfig.url + 'masterData/agency', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới agency') + ': ' + error.response.data.message, 'error');
                });
        },

        async editAgency({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/agency', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật đại lý thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật agency') + ': ' + error.response.data.message, 'error');
                });
        },

        async deleteAgency({ commit }, p) {
            axios
                .delete($themeConfig.url + 'masterData/agencies/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Xoá agency thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    var msg = error.response.data.message;
                    if (msg.indexOf('foreign key constraint') >= 0) {
                        msg = 'Vui lòng xoá người dùng của đại lý. Đại lý có giao dịch nạp tiền, mua hàng, rút tiền sẽ xoá không được';
                    }
                    p.vm.$msg(p.vm.$t('Lỗi xoá đại lý: ') + ': ' + msg, 'error');
                });
        },

        //người dùng
        async checkUniqueUser({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/check_users', params)
                .then((response) => {
                    commit('SET_UNI_USERS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchUsers({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/users', params)
                .then((response) => {
                    commit('SET_USERS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async newUser({ commit }, p) {
            axios
                .post($themeConfig.url + 'masterData/user', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới người dùng') + error.response.data.message, 'error');
                });
        },
        async exchangePricesPrd({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/exchange_prices_prd', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.exchangePriceModal.hide();
                    p.vm.$msg(p.vm.$t('Exchange all product prices according to current prices at success.!'));
                })
                .catch((error) => {
                    p.vm.$msg(p.vm.$t('Error changing all product prices to the current exchange rate'), 'error');
                });
        },
        async exchangeCurrentRateUsd({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/update_current_rate_usd', p.params)
                .then((response) => {
                    p.vm.$msg(p.vm.$t('Update current rate success'));
                })
                .catch((error) => {
                    p.vm.$msg(p.vm.$t('Update Current Rate error'), 'error');
                });
        },
        async editUser({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/users', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật người dùng') + ': ' + error.response.data.message, 'error');
                });
        },

        updateFavorite({ commit }, p) {
            return axios
                .put($themeConfig.url + 'masterData/product_favorites', p.params)
                .then((response) => {
                    if (p.params.showFavourite == 1) {
                        p.vm.$msg(p.vm.$t('Collect successfully'));
                    } else {
                        p.vm.$msg(p.vm.$t('Cancel successfully'));
                    }
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật favorites') + ': ' + error.response.data.message, 'error');
                    return error;
                });
        },

        async userLockUnlock({ commit }, p) {
            console.log(p);
            axios
                .put($themeConfig.url + 'user-status/' + p.params.user_id)
                .then((response) => {
                    p.vm.loadItems();
                    //p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật người dùng'), 'error');
                });
        },

        async deleteUser({ commit }, p) {
            axios
                .delete($themeConfig.url + 'masterData/users/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Xoá người dùng thành công!'));
                })
                .catch((error) => {
                    console.log(error.response.data);
                    var msg = error.response.data.message;
                    if (msg.indexOf('foreign key constraint') >= 0) {
                        msg = 'Vui lòng bỏ phân quyền. Người dùng có giao dịch nạp tiền, mua hàng, rút tiền sẽ xoá không được';
                    }
                    p.vm.$msg(p.vm.$t('Lỗi xoá người dùng') + ': ' + msg, 'error');
                });
        },

        //Master Data
        async fetchMd({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/' + p.type, p.params)
                .then((response) => {
                    if (p.type == 'products') {
                        response.data.result.forEach((i) => {
                            if (i.category_ids && i.category_ids != '') {
                                i.category_ids = JSON.parse(i.category_ids);
                            } else {
                                i.category_ids = [];
                            }
                            if (i.countries && i.countries != '') {
                                i.countries = JSON.parse(i.countries);
                            } else {
                                i.countries = [];
                            }
                        });
                    }
                    commit('SET_MDS', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchProvider({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/providers', p.params)
                .then((response) => {
                    commit('SET_PROVIDERS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchEmailTemplates({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/email_templates', p.params)
                .then((response) => {
                    commit('SET_EMAIL_TEMPLATES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchCountryTags({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/country_tags', p.params)
                .then((response) => {
                    commit('SET_COUNTRY_TAGS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchWarehouses({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/warehouses', p.params)
                .then((response) => {
                    commit('SET_WAREHOUSES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async newMd({ commit }, p) {
            axios
                .post($themeConfig.url + 'masterData/' + p.type, p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới, chi tiết: ' + error.response.data.message), 'error');
                });
        },
        async editMd({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/' + p.type, p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật, chi tiết: ' + error.response.data.message), 'error');
                });
        },
        async deleteMd({ commit }, p) {
            axios
                .delete($themeConfig.url + 'masterData/' + p.type + '/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Xoá dữ liệu thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi xoá dữ liệu, chi tiết: ' + error.response.data.message), 'error');
                });
        },
        async editActiveUser({ commit }, p) {
            axios
                .put($themeConfig.url + 'activeUser', p.params)
                .then((response) => {
                    p.vm.$msg(p.vm.$t('Active successfully.....!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Active unsuccessfully: ' + error.response.data.message), 'error');
                });
        },
        async updateSession({ commit }, p) {
            axios
                .put($themeConfig.url + 'updateSession', p.params)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    modules: {},
};
