<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <Header></Header>
        <!--  END NAVBAR  -->

        <!--  BEGIN MAIN CONTAINER  -->
        <div
            class="main-container"
            id="container"
            :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
        >
            <!--  BEGIN OVERLAY  -->
            <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
            <div class="search-overlay" :class="{ show: $store.state.is_show_search }" @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>
            <!-- END OVERLAY -->

            <!--  BEGIN SIDEBAR  -->
            <Sidebar></Sidebar>
            <!--  END SIDEBAR  -->

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content">
                <router-view />

                <!-- BEGIN FOOTER -->
                <Footer></Footer>
                <!-- END FOOTER -->
            </div>
            <!--  END CONTENT AREA  -->
        </div>
    </div>
</template>

<script setup>
    import Header from '@/components/layout/header.vue';
    import Sidebar from '@/components/layout/sidebar.vue';
    import Footer from '@/components/layout/footer.vue';
    import appSettings from '@/components/app-settings.vue';
</script>
<script>
    import '@/assets/sass/widgets/widgets.scss';
    export default {
        computed: {
            balance() {
                var x = this.$store.getters.getBalance;
                return x == null ? '' : x;
            },
        },
        // mounted() {
        //     var userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
        //     if (userData) {
        //         const script = document.createElement('script');
        //         script.src = '//fw-cdn.com/11017025/3765985.js';
        //         script.setAttribute('chat', 'true');
        //         script.setAttribute('widgetId', 'f5b182c0-63df-4cf8-91c3-08a54b3edd3a');

        //         script.addEventListener('load', () => {
        //             window.fwcrm.on('user:created', function () {
        //                 window.fcWidget.setExternalId(userData.id);
        //                 window.fcWidget.user.setEmail(userData.email);
        //                 window.fcWidget.user.setFirstName(userData.agency.agency_name);
        //                 window.fcWidget.user.setPhone(userData.mobile);
        //                 // window.fcWidget.user.setProperties({
        //                 //     agency_level: userData.agency.agency_level,
        //                 //     balance: balance.result,
        //                 // });
        //             });
        //         });
        //         document.body.appendChild(script);
        //     }
        // },
        mounted() {
            var userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
            var restoreId = localStorage.getItem('restoreIdFreschat') ? localStorage.getItem('restoreIdFreschat') : null;
            if (userData) {
                const script = document.createElement('script');
                script.src = '//fw-cdn.com/11017025/3765985.js';
                script.setAttribute('chat', 'true');
                script.setAttribute('widgetId', 'f5b182c0-63df-4cf8-91c3-08a54b3edd3a');
                script.setAttribute('restoreId', restoreId);
                document.body.appendChild(script);

                // console.log('restoreId');
                // console.log(restoreId);

                window.fcSettings = {
                    onInit: function () {
                        window.fcWidgetMessengerConfig = {
                            externalId: 'GGG_ID_' + userData.id, // user's id unique to your system
                            restoreId: restoreId, // Which need to be fetched from your DB
                            // externalId: 'namhai8', // user's id unique to your system
                            // restoreId: null, // Which need to be fetched from your DB
                        };

                        window.fcWidget.user.get(function (resp) {
                            var status = resp && resp.status,
                                data = resp && resp.data;

                            // console.log('===1===');
                            // console.log('status: ' + status);
                            // console.log('resp: ' + JSON.stringify(resp));
                            // console.log('data: ' + JSON.stringify(data).restoreId);
                            // console.log('===/1===');
                            if (status !== 200) {
                                window.fcWidget.user.setProperties({
                                    firstName: userData.username, // user's first name
                                    email: userData.email, // user's email address
                                    phone: userData.mobile,
                                });
                                window.fcWidget.on('user:created', function (resp) {
                                    // console.log('create');
                                    var status = resp && resp.status,
                                        data = resp && resp.data;
                                    // console.log('===2===');
                                    // console.log('status: ' + status);
                                    // console.log('resp: ' + JSON.stringify(resp));
                                    // console.log('data: ' + JSON.stringify(data));
                                    // console.log('===/2===');
                                    if (status === 200) {
                                        // console.log(data);
                                        if (data.restoreId) {
                                            // console.log(restoreIdFreschat);
                                            // console.log(data.restoreId);
                                            localStorage.setItem('restoreIdFreschat', data.restoreId.toString());
                                        }
                                    }
                                });
                            }
                        });
                    },
                };

                //     script.addEventListener('load', () => {
                //         window.fwcrm.on('user:created', function () {
                //             window.fcWidget.setExternalId(userData.id);
                //             window.fcWidget.user.setEmail(userData.email);
                //             window.fcWidget.user.setFirstName(userData.agency.agency_name);
                //             window.fcWidget.user.setPhone(userData.mobile);
                //             // window.fcWidget.user.setProperties({
                //             //     agency_level: userData.agency.agency_level,
                //             //     balance: balance.result,
                //             // });
                //         });
                //     });
                //     document.body.appendChild(script);
            }
        },
        watch: {
            '$store.getters.getState': function (o, v) {
                this.$loading(this.$store.getters.getState);
            },
        },
    };
</script>

<style>
    .vgt-table td,
    .vgt-table th {
        padding: 5px !important;
        vertical-align: middle !important;
        font-size: 0.85rem;
    }
    .vgt-wrap__footer {
        padding: 6px 12px !important;
        background: transparent !important;
        border-top: none !important;
    }
    .vgt-wrap__footer .footer__navigation,
    .vgt-wrap__footer .footer__navigation__page-btn span,
    .vgt-wrap__footer .footer__row-count__label,
    .vgt-wrap__footer .footer__row-count__select {
        font-size: 0.85rem !important;
        margin-bottom: 0 !important;
    }
    .vgt-table th {
        text-align: center;
    }
    .vgt-inner-wrap {
        border-radius: 0.25rem;
        box-shadow: none !important;
    }
    .vgt-table thead th {
        background: transparent !important;
        color: #4600b9 !important;
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }
    .vgt-table th.line-numbers,
    .vgt-table th.vgt-checkbox-col {
        background: transparent !important;
    }
    .dark .vgt-wrap__footer,
    .dark .vgt-table,
    .dark .vgt-table tr,
    .dark .vgt-table td,
    .dark .vgt-table th {
        background: #0e1726 !important;
        border-color: #3b3f5c !important;
        color: #d3d3d3 !important;
    }
    .dark input {
        background: #3b3f5c !important;
        border-color: #0e1726 !important;
        color: #d3d3d3 !important;
    }
    .vue-select {
        border-color: #bfc9d4 !important;
        width: 100% !important;
    }
    .vue-select.is-invalid {
        border-color: #dc3545 !important;
        width: 100% !important;
    }
    .vue-select.is-invalid {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23e7515a%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-x%27%3e%3cline x1=%2718%27 y1=%276%27 x2=%276%27 y2=%2718%27%3e%3c/line%3e%3cline x1=%276%27 y1=%276%27 x2=%2718%27 y2=%2718%27%3e%3c/line%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .vue-dropdown-item.highlighted {
        background-color: #4361ee !important;
        color: #f1f2f3 !important;
    }
    .vue-dropdown-item.selected.highlighted {
        background-color: #343a40 !important;
        color: #f1f2f3 !important;
    }
    .vue-input input[readonly] {
        background: transparent !important;
    }
    .vue-select,
    table.vgt-table,
    .vgt-wrap__footer,
    .vgt-input,
    .vgt-select,
    .form-control,
    .modal-content .modal-footer,
    .modal-content .modal-header,
    .table-bordered,
    .table-bordered td,
    .table-bordered th,
    .vgt-table.bordered td,
    .vgt-table.bordered th {
        border-width: 0.5px !important;
    }
    .form-label {
        margin-top: 0.5rem;
        margin-bottom: 0 !important;
    }
    .vue-select.is-valid,
    .form-select.is-valid,
    .form-control.is-valid {
        border-color: #4361ee !important;
    }
    .multiselect-option.is-selected {
        background-color: #4361ee !important;
    }
    .multiselect-caret {
        margin: 0 !important;
    }
    .multiselect-single-label-text {
        font-size: 13px;
        color: #3b3f5c;
    }
    .vgt-responsive {
        min-height: 250px;
    }
    .multiselect-option,
    .multiselect-placeholder {
        font-size: 13px !important;
    }
    .vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
        border-right: 6px solid #4361ee !important;
    }
    .vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
        border-left: 6px solid #4361ee !important;
    }
    input[disabled],
    select[disabled],
    textarea[disabled],
    input[readonly],
    select[readonly],
    textarea[readonly] {
        background-color: #f9f9f9 !important;
        color: #555 !important;
    }
    input[type='checkbox'][disabled] {
        background-color: #4361ee !important;
        color: #fff !important;
    }
    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: -3px !important;
    }
    .pricing-table #pricingWrapper {
        padding: 0 !important;
    }
    .pricing-table #pricingWrapper .stacked .card-header,
    .pricing-table #pricingWrapper .stacked .card-footer {
        border-bottom: 0.5px solid #bfc9d4 !important;
    }
    .pricing-table #pricingWrapper .stacked {
        border: 0.5px solid #bfc9d4 !important;
    }
    .pricing-table .border-dash {
        border-top: 0.5px dashed #e0e6ed;
    }
    .pricing-table .widget {
        height: auto !important;
    }
    .pricing-table #pricingWrapper .stacked .card-header .card-price {
        width: 10.25rem !important;
        height: 4.25rem !important;
        margin-top: -30px !important;
        line-height: 4.25rem !important;
        font-size: 1.475rem;
    }
    .summary-expenses .form-control-sm {
        padding: 3px 6px !important;
    }
    .formkit-messages {
        padding: 2px 0 0 3px;
        margin: 0 0 6px 0px;
        list-style-type: none;
        color: #dc3545;
        font-size: 0.8rem;
    }
    [data-invalid='true'] .formkit-input {
        border: 1px solid #dc3545;
    }
</style>
