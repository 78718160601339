<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div
            class="header-container fixed-top"
            :class="[
                !$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '',
                $store.state.menu_style == 'horizontal' ? 'header-horizontal' : $store.state.menu_style == 'vertical' ? 'header-vertical' : 'header-collapsible-vertical',
            ]"
        >
            <header class="header navbar navbar-expand-sm">
                <!-- <div class="d-none horizontal-menu me-2">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div> -->

                <div class="bar-menu-res horizontal-menu ms-3 d-none">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>
                <ul
                    class="header-logo navbar-item theme-brand flex-row text-center me-2 d-none d-sm-block"
                    :class="[$store.state.menu_style == 'horizontal' ? 'header-horizontal' : $store.state.menu_style == 'vertical' ? 'header-vertical' : 'header-collapsible-vertical']"
                >
                    <li class="nav-item theme-logo">
                        <router-link to="/">
                            <img src="@/assets/images/Logo12.svg" class="navbar-logo" alt="logo" />
                        </router-link>
                    </li>
                </ul>

                <ul class="navbar-item flex-row ms-md-0 ms-auto">
                    <header
                        class="bar-menu header navbar navbar-expand-sm"
                        :class="[$store.state.menu_style == 'horizontal' ? 'header-horizontal' : $store.state.menu_style == 'vertical' ? 'header-vertical' : 'header-collapsible-vertical']"
                    >
                        <a href="javascript:void(0);" class="sidebarCollapse mx-3" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-menu"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </a>

                        <!-- Portal vue/Teleport for Breadcrumb -->
                        <div id="breadcrumb" class="vue-portal-target pt-1"></div>
                    </header>
                    <!-- <li class="nav-item align-self-center search-animated" :class="{ 'show-search': $store.state.is_show_search }">
                        <svg
                            @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-search toggle-search"
                        >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                        <form v-on:submit.prevent class="form-inline search-full form-inline search" :class="{ 'input-focused': $store.state.is_show_search }">
                            <div class="search-bar">
                                <input type="text" @keypress.enter="goToSub" v-model="search" class="form-control search-form-control ms-lg-auto" :placeholder="$t('Search...')" />
                            </div>
                        </form>
                    </li> -->
                </ul>

                <div class="navbar-item flex-row ms-md-auto">
                    <ul class="navbar-item flex-row ms-md-0 ms-auto">
                        <li class="nav-item align-self-center search-animated" :class="{ 'show-search': $store.state.is_show_search }">
                            <svg
                                @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-search toggle-search"
                            >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <form v-on:submit.prevent class="form-inline search-full form-inline search" :class="{ 'input-focused': $store.state.is_show_search }">
                                <div class="search-bar d-flex">
                                    <input
                                        type="text"
                                        @keypress.enter="goToSub()"
                                        v-model="search"
                                        class="form-control search-form-control search-header ms-lg-auto"
                                        :placeholder="$t('Search by ICCID, OrderId, Msisdn,...')"
                                    />
                                    <!-- <div class="dropdown d-none d-md-block">
                                        <a style="height: 100%; border-radius: 0px 6px 6px 0px" class="btn btn-secondary dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="icon-drop-search d-flex align-items-center"><vue-feather type="settings" stroke="#fff"></vue-feather></div>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-search">
                                            <li style="cursor: pointer" @click="goToSub('BY_ORDER_ID')"><a class="dropdown-item" style="padding: 3px 16px !important">Search by OrderId</a></li>
                                            <li style="cursor: pointer" @click="goToSub('BY_ICCID')"><a class="dropdown-item" style="padding: 3px 16px !important">Search by ICCID</a></li>
                                            <li style="cursor: pointer" @click="goToSub('BY_MSISDN')"><a class="dropdown-item" style="padding: 3px 16px !important">Search by Msisdn</a></li>
                                        </ul>
                                    </div> -->
                                </div>
                            </form>
                        </li>
                    </ul>
                    <!-- <div class="dark-mode d-flex align-items-center"> -->
                    <!-- <a v-if="$store.state.dark_mode == 'light'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('dark')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-sun"
                            >
                                <circle cx="12" cy="12" r="5"></circle>
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                            </svg>
                            <span class="ms-2">{{ $t('Light') }}</span>
                        </a>
                        <a v-if="$store.state.dark_mode == 'dark'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('system')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-moon"
                            >
                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                            </svg>
                            <span class="ms-2">{{ $t('Dark') }}</span>
                        </a> -->
                    <!-- <a v-if="$store.state.dark_mode == 'system'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('light')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-airplay"
                            >
                                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                <polygon points="12 15 17 21 7 21 12 15"></polygon>
                            </svg>
                            <span class="ms-2">{{ $t('System') }}</span>
                        </a> -->
                    <!-- </div> -->

                    <!-- <div class="dropdown nav-item language-dropdown btn-group">
                        <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
                            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="ddllang">
                            <perfect-scrollbar>
                                <li v-for="item in countryList" :key="item.code">
                                    <a href="javascript:;" class="dropdown-item d-flex align-items-center" :class="{ active: $i18n.locale === item.code }" @click.prevent="changeLanguage(item)">
                                        <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span>{{ $t(item.name) }}</span>
                                    </a>
                                </li>
                            </perfect-scrollbar>
                        </ul>
                    </div> -->

                    <div class="dropdown nav-item notification-dropdown btn-group">
                        <!-- <a href="javascript:;" id="ddlnotify" @click="goWarehouse()" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link"> -->
                        <a class="btn dropdown-toggle btn-icon-only nav-link" :data-bs-toggle="userData && userData.agency.agency_id != 1 ? 'dropdown' : ''" aria-expanded="false">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-bell"
                            >
                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                            </svg>
                            <span class="badge badge-success d-flex align-items-center justify-content-center" v-if="userData"> {{ userData.agency.agency_id == 1 ? '0' : '1' }}</span>
                        </a>

                        <ul v-if="userData && userData.agency.agency_id != 1" class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify">
                            <li role="presentation" class="p-3">
                                <h3 class="title-notify">Introducing version 1.1</h3>
                                <ul class="contacts-block list-unstyled content-notify">
                                    <li>- New homepage interface</li>
                                    <li>- Personalize users by menu, country, dashboard</li>
                                    <li>- Buy esim with fewer steps</li>
                                    <li>- My eSIM feature with new customer support experiences</li>
                                    <li>- Billing interface is more transparent</li>
                                    <li>
                                        - If you have experience problems and need to return to the old version,
                                        <span class="btn-roll-back" @click="handleChangeRollBackV1()"> please click here. </span>
                                    </li>
                                    <li>
                                        - If you want to experience the features of the new version,

                                        <span class="btn-roll-back" @click="handleChangeRollBackV1_1()"> please click here. </span>
                                    </li>
                                </ul>
                            </li>
                            <!-- <li role="presentation" v-for="(n, stt) in notifications" :key="stt">
                                <a href="javascript:;" class="dropdown-item">
                                    <div class="media server-log">
                                        <div class="media-body">
                                            <div class="data-info">
                                                <h6 class="">Đơn {{ n.order.id }}, tổng tiền {{ n.order.total_price }}, người bán {{ n.order.user_id }}, ghi chú: {{ n.order.notes }}</h6>
                                                <p class="">
                                                    <small>{{ n.dateTime }}</small>
                                                </p>
                                            </div>

                                            <div
                                                @click="
                                                    loading = true;
                                                    notifications.splice(stt, 1);
                                                    loading = false;
                                                "
                                            >
                                                <vue-feather type="check" size="16px" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li> -->
                        </ul>
                    </div>

                    <div class="dropdown nav-item user-profile-dropdown btn-group">
                        <!-- <span class="text-primary fw-bold">{{$store.getters.getUserInfo.agency.agency_name}}</span> <span role="button" @click="$router.push({name:'balances'})">{{$t('balance')}}</span> <span role="button" @click="$router.push({name:'balances'})" class="text-secondary fw-bold">{{$number(balance)}}đ</span> -->

                        <a id="ddluser" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <img @click="$router.push({ name: 'profile' })" class="avatar-profile" v-if="avatar" :src="avatar" alt="avatar" />
                            <img @click="$router.push({ name: 'profile' })" class="avatar-profile" v-else src="@/assets/images/profile-16.jpeg" alt="avatar" />
                            <div class="info-user-profile">
                                <p @click="$router.push({ name: 'profile' })" class="user-name">{{ userData && userData.agency.agency_name }}</p>
                                <p class="info-balance">
                                    Balance
                                    <span @click="$router.push({ name: 'billing' })" v-if="balance">{{ $number(balance) }}đ</span>
                                    <span @click="$router.push({ name: 'billing' })" v-else>{{ $number(0) }}đ</span>
                                </p>
                            </div>
                        </a>

                        <vue-feather data-bs-toggle="dropdown" aria-expanded="false" class="icon-down" :type="'chevron-down'"></vue-feather>
                        <ul ref="dropdownMenuInforUser" class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                            <!-- <li role="presentation">
                                <router-link to="/balances" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-user"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    {{ userData && userData.username }} {{ $t('Profile') }}
                                </router-link>
                            </li> -->
                            <!-- <li role="presentation">
                                <router-link to="/helpdesk" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-user"
                                    >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                    {{ $t('Helpdesk') }}
                                </router-link>
                            </li> -->
                            <!-- <li role="presentation">
                                <router-link to="/tickets" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-phone-call"
                                    >
                                        <path
                                            d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                                        ></path>
                                    </svg>
                                    {{ $t('Support tickets') }}
                                </router-link>
                            </li> -->
                            <li style="cursor: pointer" role="presentation">
                                <a @click="logout" class="dropdown-item" style="padding: 3px 16px !important">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-log-out"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>
                                    {{ $t('Sign Out') }}
                                </a>
                            </li>
                        </ul>
                        <!-- <svg class="icon-down" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.5892 0.410764C11.2638 0.0853279 10.7361 0.0853279 10.4107 0.410764L5.99996 4.82151L1.58921 0.410764C1.26378 0.0853279 0.736141 0.0853279 0.410704 0.410764C0.0852666 0.736201 0.0852666 1.26384 0.410704 1.58928L5.70533 6.8839C5.86805 7.04662 6.13187 7.04662 6.29459 6.8839L11.5892 1.58928C11.9147 1.26384 11.9147 0.736201 11.5892 0.410764Z"
                                fill="#667085"
                            />
                        </svg> -->
                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <!-- <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                Portal vue/Teleport for Breadcrumb
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div> -->
        <!--  END NAVBAR  -->
        <!--  BEGIN TOPBAR  -->
        <Horizontal></Horizontal>
        <!--  END TOPBAR  -->
    </div>
</template>

<script setup>
    import { onMounted, onUnmounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import router from '@/router';
    import SockJS from 'sockjs-client';
    import Stomp from 'webstomp-client';
    import Horizontal from '@/components/layout/horizontal.vue';

    import { $themeConfig } from '@themeConfig';
    import axios from 'axios';
    const store = useStore();

    const selectedLang = ref(null);
    const search = ref('');
    const userData = ref(null);
    const avatar = ref(null);
    const countryList = ref(store.state.countryList);

    const socket = ref(null);
    const stompClient = ref(null);
    const connected = ref(false);
    const loading = ref(false);
    const notifications = ref([]);
    const status = ref([]);

    const i18n = useI18n();

    const goToSub = () => {
        if (search.value != '') {
            // router.push({ name: 'subs-mgmt', query: { dt_order: search.value } });
            document.location.href = '/my-esims?order_dt=' + search.value;
        }
        // if (search.value != '') {
        // switch (type) {
        //     case 'BY_ORDER_ID':
        //         document.location.href = '/my-esims?orderId=' + search.value;
        //         break;
        //     case 'BY_ICCID':
        //         document.location.href = '/my-esims?iccid=' + search.value;
        //         break;
        //     case 'BY_MSISDN':
        //         document.location.href = '/my-esims?msisdn=' + search.value;
        //         break;
        //     default:
        // }
        // }
    };
    const goWarehouse = () => {
        document.location.href = '/orders';
    };

    const connect = () => {
        socket.value = new SockJS('http://localhost:9010/websocket');
        stompClient.value = Stomp.over(socket);
        //console.log(stompClient);
        stompClient.value.connect(
            {},
            (frame) => {
                connected.value = true;
                stompClient.value.subscribe('/topic/notify', (tick) => {
                    //console.log(tick.body);
                    loading.value = true;
                    var x = JSON.parse(tick.body);
                    x.dateTime = new Date();
                    notifications.value.push(x);
                    loading.value = false;
                });
            },
            (error) => {
                console.log(error);
                connected.value = false;
            }
        );
    };
    const disconnect = () => {
        if (stompClient.value) {
            stompClient.value.disconnect();
        }
        connected.value = false;
    };

    const subscriber_status = async () => {
        await axios
            .post($themeConfig.url + 'getMasterData/subscriber_status', { columnFilters: {}, sort: [], page: 1, pageSize: 100 })
            .then((response) => {
                status.result = JSON.stringify(response.data.result);
                console.log('........' + status.result);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
        if (localStorage.userData) {
            avatar.value = JSON.parse(localStorage.userData).avatar;
            userData.value = JSON.parse(localStorage.userData);
        }
        // connect();
        //subscriber_status();
        //console.log("......thent.." + status.result);
    });
    onUnmounted(() => {
        //disconnect();
    });

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };
</script>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { Icon } from '@iconify/vue';
    // const store = useStore();

    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);
        i18n.locale.value = item.code;
        window.location.reload();
    };

    export default {
        components: {
            Icon,
        },
        name: 'Header',

        mounted() {
            this.$store.dispatch('fetchBalance');
            this.menu_style = this.$store.state.menu_style;
            // window.addEventListener('resize', () => {
            //     this.mw = window.screen.width;
            // });

            // if (this.mw >= 768) {
            //     this.$store.dispatch('hideSlideBar');
            // }
        },
        computed: {
            balance() {
                var x = this.$store.getters.getBalance;
                return x == null ? '' : x;
            },
        },

        methods: {
            handleChangeRollBackV1() {
                this.$store.dispatch('updateUserGroupAgecyNew', { vm: this, params: { id: JSON.parse(localStorage.getItem('userData')).id, groups: ['AGENCY_ADMIN'] } });
                this.$store.dispatch('fetchMenu');
                this.$store.commit('setDefaultMenu', 'Home');
                this.$router.push({ name: 'sales' });
            },
            handleChangeRollBackV1_1() {
                this.$store.dispatch('updateUserGroupAgecyNew', { vm: this, params: { id: JSON.parse(localStorage.getItem('userData')).id, groups: ['AGENCY_NEW'] } });
                this.$store.dispatch('fetchMenu');
                this.$store.commit('setDefaultMenu', 'Home');
                this.$router.push({ name: 'dashboard' });
            },
            logout() {
                console.log(localStorage);
                this.updateSession();
                // localStorage.clear();
                localStorage.removeItem('userData');
                localStorage.removeItem('token');
                localStorage.removeItem('pageViewCookie');
                // localStorage.removeItem('layout_location_grid_new');
                router.go({ name: localStorage.getItem('default_menu') ? localStorage.getItem('default_menu') : 'Home' });
            },

            async updateSession() {
                this.$store.dispatch('updateSession', {
                    params: { columnFilters: {}, sort: [], page: 1, pageSize: 500 },
                });
            },
        },
        data() {
            return {
                mw: window.screen.width,
                // menu_style: 'vertical',
            };
        },
    };
</script>
<style>
    .sidebar-wrapper {
        left: 0;
        width: 228px;
    }
    #sidebar {
        background: #fff !important;
    }
    #sidebar ul.menu-categories.ps {
        border-right: 0 !important;
        box-shadow: 2px 0px 32px rgba(0, 0, 0, 0.1);
    }

    #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded='true']:not([data-active='true']) {
        background: #f6f9fb !important;
        /* color: #4600b9 !important; */
        color: #0998ff !important;
    }
    #sidebar ul.menu-categories ul.submenu > li a.active {
        /* color: #4600b9 !important; */
        color: #0998ff !important;
    }

    .navbar .navbar-item .nav-item form.form-inline input.search-form-control {
        width: 280px !important;
        background: #f0f0f0 !important;
        border: none !important;
    }
    /* .search-header {
        border-radius: 8px 0px 0px 8px !important;
    } */
    .search-header::placeholder {
        font-size: 13px !important;
    }
    .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu-search.dropdown-menu.show {
        opacity: 1;
        visibility: visible;
        top: 34px !important;
    }
    .icon-drop-search svg {
        margin: 0px !important;
        position: inherit !important;
        /* height: 14px !important; */
    }
    .navbar .navbar-item .nav-item.search-animated svg {
        color: #000 !important;
    }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
        color: #667085 !important;
    }

    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link span.badge {
        width: 15px;
        height: 15px;
        background: #f04438;
        top: -2px;
        right: -9px;
    }
    .dropdown .nav-item .user-profile-dropdown .btn-group {
        position: absolute;
    }

    .icon-down {
        position: relative !important;
        bottom: -9px !important;
        right: -5px;
        width: 18px !important;
        cursor: pointer;
    }
    .icon-down:hover {
        color: #0094ff !important;
    }
    .active-menu-type {
        color: #0094ff !important;
    }
    .navbar .navbar-item .nav-item .form-inline.search.input-focused .dropdown {
        display: block !important;
    }
    .title-notify {
        font-size: 17px;
    }
    .content-notify {
        font-size: 14px;
    }
    .btn-roll-back {
        cursor: pointer;
        color: #0998ff;
    }
    .btn-roll-back:hover {
        transition: all 0.3s ease-in-out;
        text-decoration: underline;
    }
</style>
