export default [
    {
        path: '/subs-mgmt',
        name: 'subs-mgmt',
        component: () => import('../views/mgmt/Subs.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/esim-mgmt',
        name: 'esim-mgmt',
        component: () => import('../views/mgmt/eSIM.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/esim/DashboardAgentNew.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sales',
        name: 'sales',
        component: () => import('../views/esim/Dashboard.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sales-order',
        name: 'sales-order',
        component: () => import('../views/esim/SalesOrderNew.vue'),
        // component: () => import('../views/esim/BuyEsims.vue'), new
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/buy-esims',
        name: 'buy-esims',
        component: () => import('../views/esim/ParentBuyEsims.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/billing',
        name: 'billing',
        component: () => import('../views/esim/Billing.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    // {
    //     path: '/export-excel',
    //     name: 'export-excel',
    //     component: () => import('../views/esim/ExportExcel.vue'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
        path: '/for-control',
        name: 'for-control',
        component: () => import('../views/esim/ParentForControl.vue'),
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/my-esims',
        name: 'my-esims',
        component: () => import('../views/esim/MyEsim.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/import-esims',
        name: 'import-esims',
        component: () => import('../views/esim/ImportEsim.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/log-action-orders',
        name: 'log-action-orders',
        component: () => import('../views/esim/LogActionOrders.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/agency-deposit',
        name: 'agency-deposit',
        component: () => import('../views/mgmt/Deposit.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/agency-money',
        name: 'agency-money',
        component: () => import('../views/mgmt/Money.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/balances',
        name: 'balances',
        component: () => import('../views/mgmt/Money.vue'),
        // component: () => import('../views/esim/Billing.vue'), new
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/orders',
        name: 'orders',
        component: () => import('../views/esim/Orders.vue'),
        // component: () => import('../views/esim/MyEsim.vue'), new
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/helpdesk',
        name: 'helpdesk',
        component: () => import('../views/esim/HelpDesk.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/helpdesk-mgmt',
        name: 'helpdesk-mgmt',
        component: () => import('../views/esim/HelpDeskMgmt.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import('../views/esim/Rules.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/countries-tag',
        name: 'countries-tag',
        component: () => import('../views/esim/CountriesTags.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rule-details',
        name: 'rule-details',
        component: () => import('../views/esim/RuleDetails.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: () => import('../views/esim/Tickets.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/e/:id',
        name: 'qrcode',
        component: () => import('../views/mgmt/QrCode.vue'),
        meta: { layout: 'auth', requiresAuth: false },
    },

    // users
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/esim/Profile.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/account-setting',
        name: 'account-setting',
        component: () => import('../views/esim/Account_Setting.vue'),
        meta: {
            requiresAuth: true,
        },
    },
];
