<template>
    <!--  BEGIN SIDEBAR -->
    <div class="topbar-nav header navbar" role="banner">
        <nav class="topbar">
            <ul class="list-unstyled menu-categories" id="topAccordion" v-if="menu">
                <template v-if="userData && userData.agency.agency_level == 0">
                    <li class="menu single-menu" v-for="m in menu" :key="m.ID">
                        <a style="cursor: pointer" v-if="m.children && m.children.length > 0" class="dropdown-toggle autodroprown">
                            <div class="d-flex align-items-center">
                                <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                                <span>{{ $t(m.title) }}</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <!--router-link :to="{ name: m.route }" v-else class="dropdown-toggle autodroprown" aria-controls="dashboard" aria-expanded="false">
                        <div class="">
                            <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                            <span>{{ $t(m.title) }}</span>
                        </div>
                        <div>
                            <vue-feather v-if="m.children && m.children.length > 0" type="chevron-right"></vue-feather>
                        </div>
                    </router-link-->
                        <ul v-if="m.children && m.children.length > 0" :id="'menu_' + m.ID" class="collapse submenu list-unstyled">
                            <li v-for="mc in m.children" :key="mc.ID">
                                <router-link v-if="checkRoute(mc.route)" :to="{ name: mc.route }">
                                    {{ $t(mc.title) }}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </template>
                <template v-else>
                    <template v-for="m in menu" :key="m.ID">
                        <template v-if="m.ID == 13 && m.children && m.children.length > 0">
                            <li class="menu single-menu" v-for="mc in m.children" :key="mc.ID">
                                <router-link :to="{ name: mc.route }">
                                    <div class="">
                                        <Icon v-if="mc.icon != null" :icon="mc.icon" width="18" height="18" />
                                        <span>{{ $t(mc.title) }}</span>
                                    </div>
                                </router-link>
                            </li>
                        </template>
                        <template v-else>
                            <li class="menu single-menu" :key="m.ID">
                                <a style="cursor: pointer" v-if="m.children && m.children.length > 0" class="dropdown-toggle autodroprown">
                                    <div class="d-flex align-items-center">
                                        <vue-feather v-if="m.icon != null" :type="m.icon"></vue-feather>
                                        <span>{{ $t(m.title) }}</span>
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-chevron-down"
                                    >
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </a>
                                <ul v-if="m.children && m.children.length > 0" :id="'menu_' + m.ID" class="collapse submenu list-unstyled">
                                    <li v-for="mc in m.children" :key="mc.ID">
                                        <router-link v-if="checkRoute(mc.route)" :to="{ name: mc.route }">
                                            {{ $t(mc.title) }}
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </template>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script setup>
    import { onMounted, computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    const store = useStore();
    const router = useRouter();

    const menu_collapse = ref('dashboard');
    const menu = computed(() => {
        return store.getters.getMenu;
    });
    onMounted(() => {
        if (localStorage.userData) {
            store.dispatch('fetchMenu', router);
            var u = JSON.parse(localStorage.userData);
        }
    });

    const checkRoute = (name_) => {
        try {
            let l = router.resolve({ name: name_ });
            return true;
        } catch (e) {
            return false;
        }
    };
</script>

<script>
    import { Modal, Toast } from 'bootstrap';
    import { Icon } from '@iconify/vue';
    export default {
        components: {
            Modal,
            Toast,
            Icon,
        },
        mounted() {
            this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;     
        },
        data() {
            return {
                confirmModal: null,
                toast: null,
                path: null,
                name: null,
                titleToast: null,
                contentToast: null,
                bg: null,
                userData: null,
            };
        },
        unmounted() {},
        methods: {},
    };
</script>

<style scoped></style>
