import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueFeather from 'vue-feather';
const app = createApp(App);

app.component(VueFeather.name, VueFeather);

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();

const showMessage = (msg = '', type = 'success') => {
    const toast = window.Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, showCloseButton: true, timer: 8000 });
    toast.fire({ icon: type, title: msg, padding: '5px 10px' });
};

app.config.globalProperties.$msg = showMessage;
app.config.globalProperties.$isLoading = false;

app.config.globalProperties.$loading = function (type) {
    if (type) {
        document.getElementById('loading_id').style.display = 'block';
    } else {
        document.getElementById('loading_id').style.display = 'none';
    }
};

var numeral = require('numeral');
app.config.globalProperties.$number = function (value) {
    return numeral(value).format('0,0');
};

app.config.globalProperties.$vemail = function (email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};

app.config.globalProperties.$vPackageCode = (code) => {
    var re = /[A-Za-z0-9-_\/]+/;
    return code.match(re) != null && code.match(re)[0].length === code.length;
};

app.config.globalProperties.$vPackageProductCode = (code) => {
    var re = /[A-Z0-9\-]+/;
    return code.match(re) != null && code.match(re)[0].length === code.length;
};

app.config.globalProperties.$vDescription = (description) => {
    var re = /[^@#%]+/;
    return description.match(re) != null && description.match(re)[0].length === description.length;
};

app.config.globalProperties.$tableCellValue = (text, field) => {
    if (field == 'price' || field == 'cost_price') return app.config.globalProperties.$number(text);
    return text;
};

app.config.globalProperties.$isImage = (filename) => {
    var x = /\.(gif|jpe?g|tiff?|png|webp|bmp|jpg|PNG|JPG)$/i.test(filename);
    return x;
};

app.directive('maska', Maska.maska);
import { plugin, defaultConfig } from '@formkit/vue';

app.use(
    plugin,
    defaultConfig({
        config: {
            classes: {
                input: 'form-control form-control-sm',
                label: 'form-label',
            },
        },
    })
)
    .use(store)
    .use(router)
    .use(i18n)
    .use(PerfectScrollbar)
    .use(VueNouislider)
    .use(Maska)
    .use(ClientTable)
    .use(vue3JsonExcel)
    .use(VueFormWizard)
    .use(head)
    .mount('#app');
