import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';
import core from './core'
import md from './md'
import esim from './esim'

const routes = [
    ...core,...md,...esim
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    if (to.meta && to.meta.requiresAuth && !localStorage.token) {
        store.commit('setBeforePage',to);
        next({ name: 'login' })
    }
    next(true);
});

export default router;
