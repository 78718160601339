// APP CONFIG
export const $themeConfig = {
    lang: 'en', // en, da, de, el, es, fr, hu, it, ja, pl, pt, ru, sv, tr, zh
    theme: 'light', // light, dark, system
    navigation: 'horizontal', // vertical, collapsible-vertical, horizontal
    layout: 'full', // full, boxed-layout, large-boxed-layout
    url_retail: 'https://apiretail.gigago.com/api/wms/',
    url: 'https://api.gigago.com/api/admin/',
    socket_url:'https://agent.gigago.com'
    // url: 'http://localhost:9010/api/wms/'
};
