import { createStore } from 'vuex';
import { $themeConfig } from '@themeConfig';
import axios from 'axios';

axios.interceptors.request.use(function (config) {
    const token = localStorage.token;
    config.headers.apiKey = token;
    if (config[0] == undefined) {
        document.getElementById('loading_id').style.display = 'block';
    }
    return config;
});
axios.interceptors.response.use(
    (response) => {
        // console.log("call api")
        document.getElementById('loading_id').style.display = 'none';
        return response;
    },
    (error) => {
        document.getElementById('loading_id').style.display = 'none';
        return Promise.reject(error);
    }
);

export default {
    state: {
        isLoading: false,
        subscribers: null,
        subscriber_status: null,
        esims: null,
        uploaded_esims: null,
        esim_detail: null,
        subscriber_info: null,
        short_link: null,
        qr_code: null,
        products: null | [],
        order_info: null,
        sale_dashboard: null,
        topsale_dashboard: null,
        pd_dashboard: null,
        balance_dashboard: null,
        balance: 0,
        orders: null,
        order_trans: null,
        all_order_trans: null,
        order_imports: null,
        helpdesks: null,
        tickets: null,
        ticket_detail: null,
        ticket_comments: null,
        topsale_dashboard_agency: null,
        product_extra: null,
        agency_sale_products: null,
        totalRecords: null,
        helpdeskTypes: null,
        packageHistory: null,
        deposit_banks: null,
        order_specials: null,
        new_products: null,
        new_products_s: null,
        new_products_s1: null,
        new_product_extra: null,
        transition_history: null,
        deposit_history: null,
        list_esim_connect_detail: null,
        list_esim_connect: null,
        log_action_orders: null,
        deposit_airwallex: null,
        itel_topup_history: null,
        rules: null,
        channel_sales_report: null,
        websites: null,
        channels: null,
        balance_topup_itel: null,
        balance_tuge: null,
        balance_spark: null,
        balance_bill: null,
        detail_order_check: null,
        detail_report_website: null,
        log_step_orders: null,
    },
    mutations: {
        SET_LOG_STEP_ORDERS(state, payload) {
            state.log_step_orders = payload;
        },
        SET_DETAIL_REPORT_WEBSITE(state, payload) {
            state.detail_report_website = payload;
        },
        SET_DETAIL_ORDER_CHECK(state, payload) {
            state.detail_order_check = payload;
        },
        SET_BALANCE_TOPUP_ITEL(state, payload) {
            state.balance_topup_itel = payload;
        },
        SET_BALANCE_TUGE(state, payload) {
            state.balance_tuge = payload;
        },
        SET_BALANCE_SPARK(state, payload) {
            state.balance_spark = payload;
        },
        SET_BALANCE_BILL(state, payload) {
            state.balance_bill = payload;
        },
        SET_WEBSITES(state, payload) {
            state.websites = payload;
        },
        SET_CHANNELS(state, payload) {
            state.channels = payload;
        },
        SET_CHANNEL_SALES_REPORT(state, payload) {
            state.channel_sales_report = payload;
        },
        SET_RULES(state, payload) {
            state.rules = payload;
        },
        SET_TRANSITION_HISTORY(state, payload) {
            state.transition_history = payload;
        },
        SET_LIST_ESIM_CONNECT(state, payload) {
            state.list_esim_connect = payload;
        },
        SET_LIST_ESIM_CONNECT_DETAIL(state, payload) {
            state.list_esim_connect_detail = payload;
        },
        SET_LOG_ACTION_ORDERS(state, payload) {
            state.log_action_orders = payload;
        },
        SET_ITEL_TOPUP_HISTORY(state, payload) {
            state.itel_topup_history = payload;
        },
        SET_DEPOSIT_HISTORY(state, payload) {
            state.deposit_history = payload;
        },
        SET_SUBCRIBERS(state, payload) {
            state.subscribers = payload;
        },
        SET_SUBCRIBER_STATUS(state, payload) {
            state.subscriber_status = payload;
        },
        SET_ESIMS(state, payload) {
            state.esims = payload;
        },
        SET_UPLOADED_ESIMS(state, payload) {
            state.uploaded_esims = payload;
        },
        SET_ESIM_DETAIL(state, payload) {
            state.esim_detail = payload;
        },
        SET_SUBCRIBER_INFO(state, payload) {
            state.subscriber_info = payload;
        },
        SET_SHORT_LINK(state, payload) {
            state.short_link = payload;
        },
        SET_QR_CODE(state, payload) {
            state.qr_code = payload;
        },
        SET_PRODUCTS(state, payload) {
            state.products = payload;
        },
        SET_NEW_PRODUCTS_S(state, payload) {
            state.new_products_s = payload;
        },
        SET_NEW_PRODUCTS_S1(state, payload) {
            state.new_products_s1 = payload;
        },
        SET_PRODUCT_EXTRA(state, payload) {
            state.product_extra = payload;
        },
        SET_TOPSALE_DASHBOARD_AGENCY(state, payload) {
            state.topsale_dashboard_agency = payload;
        },
        SET_ORDER_INFO(state, payload) {
            state.order_info = payload;
        },
        SET_SALE_DASHBOARD(state, payload) {
            state.sale_dashboard = payload;
        },
        SET_TOPSALE_DASHBOARD(state, payload) {
            state.topsale_dashboard = payload;
        },

        SET_PD_DASHBOARD(state, payload) {
            state.pd_dashboard = payload;
        },
        SET_BALANCE_DASHBOARD(state, payload) {
            state.balance_dashboard = payload;
        },
        SET_BALANCE(state, payload) {
            state.balance = payload;
        },
        SET_ORDERS(state, payload) {
            state.orders = payload;
        },
        SET_ORDER_TRANS(state, payload) {
            state.order_trans = payload;
        },
        SET_ALL_ORDER_TRANS(state, payload) {
            state.all_order_trans = payload;
        },
        SET_ORDER_IMPORTS(state, payload) {
            state.order_imports = payload;
        },
        SET_HELPDESKS(state, payload) {
            state.helpdesks = payload;
        },
        SET_TICKETS(state, payload) {
            state.tickets = payload;
        },
        SET_TICKET_INFO(state, payload) {
            state.ticket_detail = payload;
        },
        SET_TICKET_COMMENTS(state, payload) {
            state.ticket_comments = payload;
        },
        SET_AGENCY_SALE_PRODUCT(state, payload) {
            state.agency_sale_products = payload;
        },
        SET_TOTAL_RECORDS(state, payload) {
            state.totalRecords = payload;
        },
        SET_HELPDESK_TYPES(state, payload) {
            state.helpdeskTypes = payload;
        },
        SET_PACKAGE_HISTORY(state, payload) {
            state.packageHistory = payload;
        },
        SET_DEPOSIT_BANKS(state, payload) {
            state.deposit_banks = payload;
        },
        SET_DEPOSIT_AIRWALLEX(state, payload) {
            state.deposit_airwallex = payload;
        },
        //
        SET_ORDER_SPECIALS(state, payload) {
            state.order_specials = payload;
        },
        SET_NEW_PRODUCTS(state, payload) {
            state.new_products = payload;
        },
        SET_NEW_PRODUCT_EXTRA(state, payload) {
            state.new_product_extra = payload;
        },
    },
    getters: {
        getLogStepOrders(state) {
            return state.log_step_orders;
        },
        getDetailReportWebSite(state) {
            return state.detail_report_website;
        },
        getDetailOrderCheck(state) {
            return state.detail_order_check;
        },
        getBalanceTopupItel(state) {
            return state.balance_topup_itel;
        },
        getBalanceTuge(state) {
            return state.balance_tuge;
        },
        getBalanceSparks(state) {
            return state.balance_spark;
        },
        getBalanceBill(state) {
            return state.balance_bill;
        },
        getChannels(state) {
            return state.channels;
        },
        getWebsites(state) {
            return state.websites;
        },
        getChannelSalesReport(state) {
            return state.channel_sales_report;
        },
        getRules(state) {
            return state.rules;
        },
        getEsimConnect(state) {
            return state.list_esim_connect;
        },
        getEsimConnectDetail(state) {
            return state.list_esim_connect_detail;
        },
        getLogActionOrder(state) {
            return state.log_action_orders;
        },
        getItelTopUpHistory(state) {
            return state.itel_topup_history;
        },
        getTransitionHistory(state) {
            return state.transition_history;
        },
        getDepositHistory(state) {
            return state.deposit_history;
        },
        getSubscribers(state) {
            return state.subscribers;
        },
        getSubscriberInfo(state) {
            return state.subscriber_info;
        },
        getSubscriberStatus(state) {
            return state.subscriber_status;
        },
        getESIMS(state) {
            return state.esims;
        },
        getUploadedEsims(state) {
            return state.uploaded_esims;
        },
        getESIMDetail(state) {
            return state.esim_detail;
        },
        getShortLink(state) {
            return state.short_link;
        },
        getQrCode(state) {
            return state.qr_code;
        },
        getProducts(state) {
            return state.products;
        },
        getNewProducts(state) {
            return state.new_products;
        },
        getNewProducts_s(state) {
            return state.new_products_s;
        },
        getNewProducts_s1(state) {
            return state.new_products_s1;
        },
        getProductExtra(state) {
            return state.product_extra;
        },
        getNewProductExtra(state) {
            return state.new_product_extra;
        },
        getOrderInfo(state) {
            return state.order_info;
        },
        getSaleDashboard(state) {
            return state.sale_dashboard;
        },
        getTopSaleDashboard(state) {
            return state.topsale_dashboard;
        },

        getPDDashboard(state) {
            return state.pd_dashboard;
        },
        getBalanceDashboard(state) {
            return state.balance_dashboard;
        },
        getBalance(state) {
            return state.balance;
        },
        getTopSaleDashboardAgency(state) {
            return state.topsale_dashboard_agency;
        },
        getOrders(state) {
            return state.orders;
        },
        getOrderTrans(state) {
            return state.order_trans;
        },
        getAllOrderTrans(state) {
            return state.all_order_trans;
        },
        getOrderImports(state) {
            return state.order_imports;
        },
        getHelpdesks(state) {
            return state.helpdesks;
        },
        getTickets(state) {
            return state.tickets;
        },
        getTicketDetail(state) {
            return state.ticket_detail;
        },
        getTicketComments(state) {
            return state.ticket_comments;
        },
        getAgencySaleProducts(state) {
            return state.agency_sale_products;
        },
        getTotalRecords(state) {
            return state.totalRecords;
        },
        getHelpdeskTypes(state) {
            return state.helpdeskTypes;
        },
        getPackageHistory(state) {
            return state.packageHistory;
        },
        getDepositBanks(state) {
            return state.deposit_banks;
        },
        getDepositBanks(state) {
            return state.deposit_banks;
        },
        getDepositAirwallex(state) {
            return state.deposit_airwallex;
        },
        getOrderSpecials(state) {
            return state.order_specials;
        },
    },
    actions: {
        async fetchCheckDetailOrder({ commit }, order_id) {
            try {
                const data = await axios.get($themeConfig.url + 'getDetailPointOrder/' + order_id, 'T');
                commit('SET_DETAIL_ORDER_CHECK', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        topup({ commit }, p) {
            return axios
                .post($themeConfig.url_retail + 'itel_topup', p.params)
                .then(async (response) => {
                    p.vm.$store.dispatch('fetchBalancePhoneNumberItel', { vm: p.vm, msisdn: p.params.msisdn });
                    p.vm.$msg(`You have recharge ${p.params.money}  into phone number ${p.params.msisdn} successfully`);
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Top up error'), 'error');
                });
        },

        //getPackageHistory
        async fetchPackageHistory({ commit }, msisdn) {
            try {
                const data = await axios.get($themeConfig.url + 'getPackageHistory/' + msisdn);
                commit('SET_PACKAGE_HISTORY', data.data.result.result);
            } catch (error) {
                console.log(error);
            }
        },

        //getBalanceTopUpItel
        async fetchBalanceTopUpItel({ commit }) {
            try {
                const data = await axios.get($themeConfig.url + 'balance_topup_itel');
                commit('SET_BALANCE_TOPUP_ITEL', data.data.result.result);
                console.log('data.data.result.result');
                console.log(data.data.result.result);
            } catch (error) {
                console.log(error);
            }
        },
        //getBalanceAcountTuge
        async fetchBalanceAcountTuge({ commit }) {
            try {
                const data = await axios.get($themeConfig.url + 'esimTugeAccount', 'T');
                commit('SET_BALANCE_TUGE', data.data.result);
                console.log('data.data.result');
                console.log(data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        //getBalanceAcountSparks
        async fetchBalanceAcountSpark({ commit }) {
            try {
                const data = await axios.get($themeConfig.url + 'getAcountBalanceSparks', 'T');
                commit('SET_BALANCE_SPARK', data.data.result);
                console.log('data.data.result');
                console.log(data.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        //getBalanceAcountBill
        async fetchBalanceAcountBill({ commit }) {
            try {
                const data = await axios.get($themeConfig.url + 'accountBalance', 'T');
                commit('SET_BALANCE_BILL', data.data.result);
                console.log('data.data.result');
                console.log(data.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        async fetchWebsites({ commit }, p) {
            try {
                const data = await axios.get($themeConfig.url + 'getWebsites');

                commit('SET_WEBSITES', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        async fetchChannels({ commit }) {
            try {
                const data = await axios.get($themeConfig.url + 'getChannels');
                commit('SET_CHANNELS', data.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        async fetchRules({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getMasterData/rules', p.params)
                .then((response) => {
                    commit('SET_RULES', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async addNewFlag({ commit }, p) {
            await axios
                .post($themeConfig.url + 'masterData/rule_flag', p.params)
                .then((response) => {
                    p.vm.handleConfig();
                    p.vm.$msg('Add new flag successfully');
                    p.vm.editModalFlag.hide();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async updateFlag({ commit }, p) {
            await axios
                .put($themeConfig.url + 'masterData/rule_flag', p.params)
                .then((response) => {
                    p.vm.handleConfig();
                    p.vm.$msg('Update flag successfully');
                    p.vm.editModalFlag.hide();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async updateOrderDetail({ commit }, p) {
            await axios
                .put($themeConfig.url + 'updateOrders/' + p.params.type + '/' + p.params.order_id + '/' + p.params.cs_notes)
                .then((response) => {
                    p.vm.$msg('Update success');
                    p.vm.changeModal.hide();
                    p.vm.loadItem();
                })
                .catch((error) => {
                    p.vm.$msg('Update errors', 'error');
                    console.log(error);
                });
        },

        async fetchChannelSalesReport({ commit }, p) {
            await axios
                .post($themeConfig.url + 'getDashboards/report_website', p)
                .then((response) => {
                    commit('SET_CHANNEL_SALES_REPORT', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchSubscribers({ commit }, params) {
            axios
                .get($themeConfig.url + 'subscribers', { params: params })
                .then((response) => {
                    commit('SET_SUBCRIBERS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchAllEsimConnected({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getInteconnectItel', params)
                .then((response) => {
                    if (params.type_id == 1) {
                        console.log(response.data);
                        commit('SET_LIST_ESIM_CONNECT_DETAIL', response.data);
                    } else if (params.type_id == 0) {
                        console.log(response.data.result);
                        commit('SET_LIST_ESIM_CONNECT', response.data);
                    }
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchItelTopupHistory({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getTopupHistorysItel', params)
                .then((response) => {
                    commit('SET_ITEL_TOPUP_HISTORY', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchSubscribers2({ commit }, params) {
            axios
                .post($themeConfig.url + 'subscribers', params)
                .then((response) => {
                    commit('SET_SUBCRIBERS', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchOrderSpecials({ commit }, params) {
            axios
                .post($themeConfig.url + 'orderSpecials', params)
                .then((response) => {
                    commit('SET_ORDER_SPECIALS', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async topupPackage({ commit }, vm) {
            axios
                .put($themeConfig.url + 'topupPackage/' + vm.currentItem.msisdn)
                .then((response) => {
                    vm.$msg(vm.$t('Topup package success'));
                })
                .catch((error) => {
                    console.log(error);
                    vm.$msg(vm.$t('Topup package error: ' + error.response.data.message), 'error');
                });
        },
        async topupPackage1({ commit }, vm) {
            axios
                .put($themeConfig.url + 'topupPackage/' + vm.topup_phone)
                .then((response) => {
                    vm.$msg(vm.$t('Topup package success'));
                })
                .catch((error) => {
                    console.log(error);
                    vm.$msg(vm.$t('Topup package error: ' + error.response.data.message), 'error');
                });
        },
        async topupPackage2({ commit }, vm) {
            var code = !vm.topup_package ? 'TOPUP' : vm.topup_package.code;
            axios
                .put($themeConfig.url + 'topupPackage2/', { msisdn: vm.topup_phone, type: vm.topup_type, pack: code, details: vm.orders })
                .then((response) => {
                    if (vm.orders.length == 1) {
                        vm.$msg(vm.$t('Topup success'));
                        vm.orders = [];
                        localStorage.removeItem('orders');
                        vm.topupListModal.hide();
                    } else {
                        vm.$msg(vm.$t('Xem kết quả Topup trong bảng'));
                    }
                    vm.isLoading = true;
                    response.data.result.forEach((m) => {
                        vm.orders.find((x) => x.msisdn == m.msisdn).status = m.status;
                    });
                    console.log(vm.orders);
                    vm.isLoading = false;
                    //vm.orders = JSON.parse(JSON.stringify(vm.orders))
                })
                .catch((error) => {
                    console.log(error);
                    vm.$msg(vm.$t('Topup package error: ' + error.response.data.message), 'error');
                });
        },
        async fetchESIMS({ commit }, params) {
            axios
                .get($themeConfig.url + 'sims', { params: params })
                .then((response) => {
                    commit('SET_ESIMS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchUploadedEsims({ commit }, params) {
            await axios
                .post($themeConfig.url + 'getMasterData/esims', params)
                .then((response) => {
                    commit('SET_UPLOADED_ESIMS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async uploadEsims({ commit }, params) {
            await axios
                .post($themeConfig.url + 'masterData/esims', params)
                .then((response) => {
                    console.log('success');
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchESIMDetail({ commit }, msin) {
            var info = {};
            await axios
                .get($themeConfig.url + 'getMsisdnFromMsin/' + msin)
                .then((response) => {
                    info.msisdn = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                });
            if (info.msisdn && info.msisdn != '') {
                return await axios
                    .get($themeConfig.url + 'getData/0' + info.msisdn.substring(2))
                    .then((response) => {
                        info.package = response.data.result.result;
                        commit('SET_ESIM_DETAIL', info);
                        return response;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            //console.log(info);

            //return resp;
        },
        getESIMStatus({ commit }, msin) {
            return axios
                .get($themeConfig.url + 'getESIMStatus/' + msin)
                .then((response) => {
                    return response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                });
            //console.log(info);

            //return resp;
        },
        async fetchOrders({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getOrders', params)
                .then((response) => {
                    commit('SET_ORDERS', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchOrderTrans({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getOrderTrans', params)
                .then((response) => {
                    commit('SET_ORDER_TRANS', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchAllOrderTrans({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getOrderTrans', params)
                .then((response) => {
                    commit('SET_ALL_ORDER_TRANS', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchLogActionOrder({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getListCheckOrders', params)
                .then((response) => {
                    commit('SET_LOG_ACTION_ORDERS', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchLogStepOrders({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getMasterData/action_buys', params, 'T')
                .then((response) => {
                    commit('SET_LOG_STEP_ORDERS', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchOrderImports({ commit }, params) {
            return axios
                .post($themeConfig.url + 'getOrderImports', params)
                .then((response) => {
                    commit('SET_ORDER_IMPORTS', response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchSubscriberStatus({ commit }) {
            axios
                .post($themeConfig.url + 'getMasterData/subscriber_status', { columnFilters: {}, sort: [], page: 1, pageSize: 100 })
                .then((response) => {
                    commit('SET_SUBCRIBER_STATUS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchSubscriberInfo({ commit }, msisdn) {
            var info = {};
            await axios
                .get($themeConfig.url + 'getBalance/' + msisdn)
                .then((response) => {
                    info.balance = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                    info.balance = {};
                });
            await axios
                .get($themeConfig.url + 'getStatus/' + msisdn)
                .then((response) => {
                    info.info = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                    info.info = {};
                });
            await axios
                .get($themeConfig.url + 'getData/' + msisdn)
                .then((response) => {
                    info.data = response.data.result.result;
                    info.data.dataMain = Math.round((info.data.dataMain * 10) / 1024 / 1024) / 10 + 'Mb';
                })
                .catch((error) => {
                    console.log(error);
                    info.data = {};
                });
            await axios
                .get($themeConfig.url + 'getPackageHistory/' + msisdn)
                .then((response) => {
                    console.log(response.data.result.result);
                    info.package_history = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });

            await axios
                .get($themeConfig.url + 'getDataHistory/' + msisdn)
                .then((response) => {
                    console.log(response.data.result.result);
                    info.history = response.data.result.result;
                    info.history = info.history.filter((i) => i.dataVolume > 0);
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });
            console.log('.......esim.js,fetchSubscriberInfo:', info);

            commit('SET_SUBCRIBER_INFO', info);
        },

        async fetchRefreshStatusNew({ commit }, p) {
            p.vm.subscriber_info.info.status = null;
            await axios
                .get($themeConfig.url + 'getStatus/' + p.msisdn, 'T')
                .then((response) => {
                    var data = response.data.result.result;
                    p.vm.subscriber_info.info.status = data.status;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.subscriber_info.info = {};
                });
        },

        async fetchStatusVNM({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            await axios
                .get($themeConfig.url + 'esimVNMStatus/' + p.msisdn, 'T')
                .then((response) => {
                    var data = response.data.result;
                    info.status = data;
                })
                .catch((error) => {
                    console.log(error);
                    info = {};
                });
            p.vm.subscriber_info = info;
        },

        async fetchRefreshRemaningDataNew({ commit }, p) {
            p.vm.subscriber_info.data.dataMain = null;
            await axios
                .get($themeConfig.url + 'getData/' + p.msisdn, 'T')
                .then((response) => {
                    var data = response.data.result.result;
                    p.vm.subscriber_info.data.dataMain = Math.round((data.dataMain * 10) / 1024 / 1024) / 10 + 'Mb';
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.subscriber_info.data = {};
                });
        },

        async fetchRefreshRemaningDataAiralo({ commit }, p) {
            p.vm.subscriber_info.status.remaining = null;
            await axios
                .get($themeConfig.url + 'esimAiraloStatus/' + p.iccid_str, 'T')
                .then((response) => {
                    // console.log("..............status:",response.data.result);
                    var data = response.data.result;
                    p.vm.subscriber_info.status.remaining = data.remaining;
                    // console.log("..............status:", info.status);
                })
                .catch((error) => {
                    console.log(error);
                    // info.status = {};
                });
        },

        async fetchRefreshRemaningDataBillconnect({ commit }, p) {
            // p.vm.subscriber_info.history[0]?.subOrderList[0].remainingTraffic = null;
            p.vm.subscriber_info.history = null;
            const status_plan_text = {
                0: 'Not used',
                1: 'In use',
                2: 'Used',
                3: 'Cancelled',
            };
            await axios
                .get($themeConfig.url + 'dataPlanUsage/' + p.iccid_str, 'T')
                .then((response) => {
                    p.vm.subscriber_info.history = response.data.result;
                    if (p.vm.subscriber_info.history && p.vm.subscriber_info.history.length > 0 && p.vm.subscriber_info.history[0]?.subOrderList?.length > 0) {
                        p.vm.subscriber_info.history[0].subOrderList[0].planStatus = status_plan_text[p.vm.subscriber_info.history[0].subOrderList[0].planStatus];
                    }
                    // console.log("..............history:", info.history[0]);
                })
                .catch((error) => {
                    console.log(error);
                    // info.history = {};
                });
        },

        async fetchSubscriberInfoSpark({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            await axios
                .get($themeConfig.url + 'esimSparkStatus/' + p.iccid_str, 'T')
                .then((response) => {
                    info.subscriber = response.data.result?.listSubscriber?.subscriberList[0].sim;
                })
                .catch((error) => {
                    console.log(error);
                    info.packageInfo = {};
                });

            if (info.subscriber != null && info.subscriber.subscriberId && info.subscriber.status != 'FREE') {
                await axios
                    .get($themeConfig.url + 'esimSparkPackageHistory/' + info.subscriber.subscriberId, 'T')
                    .then((response) => {
                        info.packageInfo = response.data.result?.listSubscriberPrepaidPackages?.packages[0];
                    })
                    .catch((error) => {
                        console.log(error);
                        info.packageInfo = {};
                    });
            }
            p.vm.subscriber_info = info;
        },

        async fetchTugePackageHistory({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            await axios
                .get($themeConfig.url + 'esimTugePackageHistory/' + p.iccid_str, 'T')
                .then((response) => {
                    info.packageHistory = response.data.result[0];
                })
                .catch((error) => {
                    console.log(error);
                    info.packageHistory = {};
                });
            p.vm.subscriber_info = info;
        },

        async fetchBalancePhoneNumberItel({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            await axios
                .get($themeConfig.url + 'getBalance/' + p.msisdn, 'T')
                .then((response) => {
                    info.balance = response.data.result.result;
                    console.log(info.balance);
                    p.vm.checkCallApiCheckCoreBalance = true;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.checkCallApiCheckCoreBalance = false;
                    info.balance = error?.response?.data?.result?.message;
                });
            p.vm.subscriber_info = info;
        },

        async fetchSubscriberInfoNew({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            await axios
                .get($themeConfig.url + 'getBalance/' + p.msisdn, 'T')
                .then((response) => {
                    info.balance = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                    info.balance = {};
                });
            await axios
                .get($themeConfig.url + 'getStatus/' + p.msisdn, 'T')
                .then((response) => {
                    info.info = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                    info.info = {};
                });
            await axios
                .get($themeConfig.url + 'getData/' + p.msisdn, 'T')
                .then((response) => {
                    info.data = response.data.result.result;
                    info.data.dataMain = Math.round((info.data.dataMain * 10) / 1024 / 1024) / 10 + 'Mb';
                })
                .catch((error) => {
                    // console.log('error=============');
                    if (error.response.data.result.message == 'MSISN not found!' && error.response.data.result.code == 404) {
                        info.data = 'expired';
                    } else {
                        info.data = {};
                    }
                });
            await axios
                .get($themeConfig.url + 'getPackageHistory/' + p.msisdn, 'T')
                .then((response) => {
                    console.log(response.data.result.result);
                    info.package_history = response.data.result.result;
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });

            await axios
                .get($themeConfig.url + 'getDataHistory/' + p.msisdn, 'T')
                .then((response) => {
                    console.log(response.data.result.result);
                    info.history = response.data.result.result;
                    info.history = info.history.filter((i) => i.dataVolume > 0);
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });

            p.vm.subscriber_info = info;
            console.log('ssssssssss');
            console.log(p.vm.subscriber_info);
        },

        async fetchAiraloInfo({ commit }, iccid_str) {
            var info = {};
            await axios
                .get($themeConfig.url + 'esimAiraloStatus/' + iccid_str)
                .then((response) => {
                    //console.log("..............status:",response.data);
                    info.status = response.data;
                    // console.log("..............status:", info.status);
                })
                .catch((error) => {
                    console.log(error);
                    info.status = {};
                });
            await axios
                .get($themeConfig.url + 'esimAiraloPackageHis/' + iccid_str)
                .then((response) => {
                    //console.log("..............history:",response.data);
                    info.history = response.data;
                    console.log('..............history:', info.history);
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });
            console.log('..............info:', info);
            commit('SET_SUBCRIBER_INFO', info);
        },

        async fetchAiraloInfoNew({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            await axios
                .get($themeConfig.url + 'esimAiraloStatus/' + p.iccid_str, 'T')
                .then((response) => {
                    // console.log("..............status:",response.data.result);
                    info.status = response.data.result;
                    // console.log("..............status:", info.status);
                })
                .catch((error) => {
                    console.log(error);
                    info.status = {};
                });

            await axios
                .get($themeConfig.url + 'esimAiraloPackageHis/' + p.iccid_str, 'T')
                .then((response) => {
                    // console.log("..............history:",response.data.result);
                    info.history = response.data.result;
                    // console.log("..............history:", info.history);
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });
            console.log('..............info:', info);
            p.vm.subscriber_info = info;
            console.log('..............subscriber_info:', p.vm.subscriber_info);
        },

        async fetchBillConnectInfo({ commit }, iccid_str) {
            var info = {};
            const status_text = {
                0: 'Not downloaded',
                1: 'Downloaded',
                2: 'Installed',
                3: 'Enabled',
                4: 'Disabled',
                5: 'Recycled',
            };
            await axios
                .get($themeConfig.url + 'esimProfileStatus/' + iccid_str)
                .then((response) => {
                    response.data.result.forEach((i) => {
                        i.status = status_text[i.status];
                    });

                    info.status = response.data.result;
                    // console.log("..............status:", info.status);
                })
                .catch((error) => {
                    console.log(error);
                    info.status = {};
                });

            await axios
                .get($themeConfig.url + 'dataPlanUsage/' + iccid_str)
                .then((response) => {
                    //console.log("..............history:",response.data);
                    info.history = response.data.result;
                    if (info.history && info.history.length > 0 && info.history[0]?.subOrderList?.length > 0) {
                        info.history[0].subOrderList[0].planStatus = status_text[info.history[0].subOrderList[0].planStatus];
                    }
                    // console.log("..............history:", info.history[0]);
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });
            console.log('..............info:', info);

            commit('SET_SUBCRIBER_INFO', info);
        },

        async fetchBillConnectInfoNew({ commit }, p) {
            p.vm.subscriber_info = null;
            var info = {};
            //  thông tin từ tài liệu nhà cung cấp
            const status_profile_text = {
                0: 'Not downloaded',
                1: 'Lownloaded',
                2: 'Installed',
                3: 'Enabled',
                4: 'Disabled',
                5: 'Recycled',
            };

            const status_plan_text = {
                0: 'Not used',
                1: 'In use',
                2: 'Used',
                3: 'Cancelled',
            };
            await axios
                .get($themeConfig.url + 'esimProfileStatus/' + p.iccid_str, 'T')
                .then((response) => {
                    response.data.result.forEach((i) => {
                        i.status = status_profile_text[i.status];
                    });

                    info.status = response.data.result;
                    // console.log("..............status:", info.status);
                })
                .catch((error) => {
                    console.log(error);
                    info.status = {};
                });

            await axios
                .get($themeConfig.url + 'dataPlanUsage/' + p.iccid_str, 'T')
                .then((response) => {
                    //console.log("..............history:",response.data);
                    info.history = response.data.result;
                    if (info.history && info.history.length > 0 && info.history[0]?.subOrderList?.length > 0) {
                        info.history[0].subOrderList[0].planStatus = status_plan_text[info.history[0].subOrderList[0].planStatus];
                    }
                    // console.log("..............history:", info.history[0]);
                })
                .catch((error) => {
                    console.log(error);
                    info.history = {};
                });
            console.log('..............info:', info);
            p.vm.subscriber_info = info;
            commit('SET_SUBCRIBER_INFO', info);
        },

        async fetchShortLink({ commit }, msin) {
            axios.get($themeConfig.url + 'short/' + msin).then((response) => {
                commit('SET_SHORT_LINK', response.data.result);
            });
        },
        async activePackage({ commit }, vm) {
            axios
                .get($themeConfig.url + 'activePackage/0' + vm.esim_detail.msisdn.substring(2))
                .then((response) => {
                    vm.$msg(vm.$t('Active package success!'));
                    vm.editModal.hide();
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Active package error') + ': ' + error.response.data.message, 'error');
                });
        },
        async activePackage1({ commit }, vm) {
            axios
                .get($themeConfig.url + 'activePackage/0' + vm.esim_detail.msisdn.substring(2))
                .then((response) => {
                    vm.$msg(vm.$t('Active package success!'));
                    vm.esimModal.hide();
                })
                .catch((error) => {
                    console.log(error);
                    vm.$msg(vm.$t('Active package error') + ': ' + error.response.data.message, 'error');
                });
        },
        async activePackage2({ commit }, vm) {
            axios
                .get($themeConfig.url + 'activePackage/0' + vm.esim_detail.msisdn.substring(2))
                .then((response) => {
                    vm.$msg(vm.$t('Active package success!'));
                    vm.sendModal.hide();
                })
                .catch((error) => {
                    console.log(error);
                    vm.$msg(vm.$t('Active package error') + ': ' + error.response.data.message, 'error');
                });
        },
        async activePackageNew({ commit }, p) {
            var phone_number = p.msisdn.startsWith('0') ? p.msisdn : `0${p.msisdn.substring(2)}`;

            axios
                .get($themeConfig.url + 'activePackage/' + phone_number)
                .then((response) => {
                    p.vm.$msg('Active package success!');
                    p.vm.$store.dispatch('fetchSubscriberInfoNew', { vm: p.vm, msisdn: p.msisdn });
                })
                .catch((error) => {
                    // console.log(error);
                    p.vm.$msg('Active package error' + ': ' + error.response.data.message, 'error');
                });
        },
        async returnEsim({ commit }, vm) {
            axios
                .put($themeConfig.url + 'returnEsim/' + vm.currentEsimItem.id + '/' + vm.currentEsimItem.msin)
                .then((response) => {
                    vm.$msg(vm.$t('Return success!'));
                    vm.loadItems();
                    vm.sendModal.hide();
                })
                .catch((error) => {
                    console.log(error);
                    vm.$msg(vm.$t('Return error') + ': ' + error.response.data.message, 'error');
                });
        },
        changeSubStatus({ commit }, p) {
            return axios
                .put($themeConfig.url + 'changeSubStatus/' + p.params.msisdn + '/' + p.params.status)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.$msg(p.vm.$t('Update subscriber status success!'));
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Update subscriber status error') + ': ' + error.response.data.message, 'error');
                });
        },
        async changeStatus({ commit }, p) {
            axios
                .put($themeConfig.url + 'changeStatus/' + p.params.id + '/' + p.params.status)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.$msg(p.vm.$t('Update status success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Update status error!') + ': ' + error.response.data.message, 'error');
                });
        },
        async changeStatusNew({ commit }, p) {
            console.log('..........', p.params);
            return axios
                .put($themeConfig.url + 'change_status_new', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.returnOrder.hide();
                    p.vm.$msg(p.vm.$t('Update status success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật status') + ': ' + error.response.data.message, 'error');
                    return console.error();
                });
        },

        async changeStatusNewv1_1({ commit }, p) {
            console.log('..........', p.params);
            return axios
                .put($themeConfig.url + 'change_status_new_v1_1', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.returnOrder.hide();
                    p.vm.$msg(p.vm.$t('Update status success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật status') + ': ' + error.response.data.message, 'error');
                    return console.error();
                });
        },

        async addNoteNew({ commit }, p) {
            axios
                .put($themeConfig.url + 'addNoteNew', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.noteModal.hide();
                    p.vm.$msg(p.vm.$t('Add note success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Add note error!') + ': ' + error.response.data.message, 'error');
                });
        },

        async changeSimStatus({ commit }, p) {
            console.log(p.params.msisdn);
            axios
                .put($themeConfig.url + 'changeSimStatus/' + p.params.msisdn + '/' + p.params.status)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.$msg(p.vm.$t('Update sim status success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Update sim status error!') + ': ' + error.response.data.message, 'error');
                });
        },
        async ReturnAll({ commit }, p) {
            axios
                .put($themeConfig.url + 'ReturnAll/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.$msg(p.vm.$t('Return order success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Update sim status error!') + ': ' + error.response.data.message, 'error');
                });
        },
        async changeOrderStatus({ commit }, p) {
            axios
                .put($themeConfig.url + 'changeOrderStatus/' + p.params.id + '/' + p.params.status)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.changeModal.hide();
                    p.vm.$msg(p.vm.$t('Update order status success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Update order status error!') + ': ' + error.response.data.message, 'error');
                });
        },
        async addNote({ commit }, p) {
            axios
                .put($themeConfig.url + 'addNote/' + p.params.id + '/' + p.params.notes)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.noteModal.hide();
                    p.vm.$msg(p.vm.$t('Add note success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Add note error!') + ': ' + error.response.data.message, 'error');
                });
        },

        async sendEmail({ commit }, p) {
            axios
                .post($themeConfig.url + 'Send-Email', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    //p.vm.changeModal.hide();
                    p.vm.$msg(p.vm.$t('Send email success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Send email error') + ': ' + error.response.data.message, 'error');
                });
        },
        async sendEmail1({ commit }, p) {
            axios
                .post($themeConfig.url + 'Send-Email', p.params)
                .then((response) => {
                    p.vm.sendModal.hide();
                    p.vm.$msg(p.vm.$t('Send email success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Send email error') + ': ' + error.response.data.message, 'error');
                });
        },

        async sendEmailNew({ commit }, p) {
            axios
                .post($themeConfig.url + 'Send-Email-New', p.params)
                .then((response) => {
                    p.vm.infoSendEmail = p.params;
                    p.vm.sendEmailSuccess.show();
                    p.vm.editModal1.hide();
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Send email error') + ': ' + error.response.data.message, 'error');
                });
        },

        async agencyDeposit({ commit }, p) {
            axios
                .put($themeConfig.url + 'deposit', p.params)
                .then((response) => {
                    if (response.data) {
                        p.vm.$msg(p.vm.$t('Deposit success!'));
                        p.vm.loadDataByAgency(p.vm.agency_id);
                        p.vm.agency_id_search = p.vm.agency_id;
                        p.vm.agency_id = 0;
                        p.vm.amount = 0;
                        p.vm.depositModal.hide();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Deposit error') + ': ' + error.response.data.message, 'error');
                });
        },
        async agencyWithdraw({ commit }, p) {
            axios
                .put($themeConfig.url + 'withdraw', p.params)
                .then((response) => {
                    p.vm.amount = 0;
                    p.vm.agency_id = 0;
                    p.vm.$msg(p.vm.$t('Withdraw success!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Withdraw error') + ': ' + error.response.data.message, 'error');
                });
        },
        async fetchESIMfromCode({ commit }, p) {
            axios.get($themeConfig.url + 'getqrcode/' + p).then((response) => {
                commit('SET_QR_CODE', response.data.result);
            });
        },
        async getQrCode({ commit }, p) {
            axios.get($themeConfig.url + 'get_qrcode/' + p).then((response) => {
                commit('SET_QR_CODE', response.data.result);
            });
        },
        async fetchProducts({ commit }, p) {
            axios.get($themeConfig.url + 'products_dashboard', { params: p }).then((response) => {
                commit('SET_PRODUCTS', response.data.result);
            });
        },

        async fetchProducts2({ commit }, p) {
            return axios.get($themeConfig.url + 'products2', { params: p }).then((response) => {
                commit('SET_PRODUCTS', response.data.result);
                commit('SET_PRODUCT_EXTRA', response.data.extra);
                return response;
            });
        },
        async fetchNewProducts({ commit }, p) {
            return axios.get($themeConfig.url + 'products_new', { params: p }).then((response) => {
                commit('SET_NEW_PRODUCTS', response.data.result);
                commit('SET_NEW_PRODUCT_EXTRA', response.data.extra);
                return response;
            });
        },
        async fetchNewProducts_s({ commit }, p) {
            axios
                .post($themeConfig.url + 'products_new_s', p.params)
                .then((response) => {
                    commit('SET_NEW_PRODUCTS_S', response.data);
                    p.vm.searchFlag = p.vm.valueSelectRe;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchNewProducts_s1({ commit }, p) {
            axios
                .post($themeConfig.url + 'products_new_s', p.params)
                .then((response) => {
                    commit('SET_NEW_PRODUCTS_S1', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchUploadProducts({ commit }, p) {
            axios.post($themeConfig.url + 'getMasterData/products', p.params).then((response) => {
                commit('SET_PRODUCTS', response.data.result);
                commit('SET_PRODUCT_EXTRA', response.data.extra);
            });
        },

        async fetchDetailReportWebSite({ commit }, p) {
            axios
                .post($themeConfig.url + 'getDetailReportWebSite', p.params, 'T')
                .then((response) => {
                    commit('SET_DETAIL_REPORT_WEBSITE', response.data.result);
                    p.vm.sliderReportOnSite = true;
                })
                .catch((error) => {
                    p.vm.sliderReportOnSite = false;
                    console.log(error);
                });
        },

        fetchVietQR({ commit }, vm) {
            var bank_id = vm.deposit_banks.find((i) => i.id == vm.currentBank).bank_id;
            var account = vm.deposit_banks.find((i) => i.id == vm.currentBank).account_number;

            return axios.get($themeConfig.url + 'vietqr/' + vm.amount, { params: { bank_id: bank_id, account_id: account } }).then((response) => {
                return response.data.result;
            });
        },
        async fetchSaleDashboard({ commit }, vm) {
            axios
                .post($themeConfig.url + 'sale_dashboard', {
                    packageDate: vm.packageDate.split(' to '),
                    orderDate: vm.orderDate.split(' to '),
                    logDate: vm.logDate.split(' to '),
                    topDate: vm.logDate.split(' to '),
                    number_day: Number(vm.number_day),
                    number_month: Number(vm.number_month),
                    number_week: Number(vm.number_week),
                    agency_id: Number(vm.agency_id_p),
                    product_id: vm.selectedProducts.toString(),
                    daySaleType: vm.daySaleType,
                    expenseType: vm.expenseType,
                })
                .then((response) => {
                    commit('SET_SALE_DASHBOARD', response.data.result);
                    console.log(vm.sale_data.daily_orders);
                });
        },
        async fetchSaleDashboardAgencyNew({ commit }, vm) {
            axios
                .post($themeConfig.url + 'sale_dashboard', {
                    packageDate: vm.formatDateFilter(vm.packageDate).split(' to '),
                    orderDate: vm.orderDate.split(' to '),
                    logDate: vm.logDate.split(' to '),
                    topDate: vm.logDate.split(' to '),
                    // number_day: Number(vm.number_day),
                    DailyDateRange: vm.formatDateFilter(vm.dailyDate).split(' to '),
                    number_month: Number(vm.number_month),
                    number_week: Number(vm.number_week),
                    agency_id: Number(vm.agency_id_p),
                    product_id: vm.selectedProducts.toString(),
                    daySaleType: vm.daySaleType,
                    expenseType: vm.expenseType,
                })
                .then((response) => {
                    commit('SET_SALE_DASHBOARD', response.data.result);
                    console.log(vm.sale_data.daily_orders);
                });
        },
        /*
        async fetchBalanceDashboards({commit},params){
            await axios.post($themeConfig.url+'balance_dashboards', params).then(response =>{
                commit('SET_BALANCE_DASHBOARD', response.data.result);
                commit('SET_TOTAL_RECORDS', response.data.totalRecords);
            })
            .catch(error => {
                console.log(error);
            })
        },
        */
        async fetchSaleDashboards({ commit }, vm) {
            axios.post($themeConfig.url + 'getDashboard/sale_dashboard', { params }).then((response) => {
                commit('SET_SALE_DASHBOARD', response.data.result);
            });
        },

        async fetchBalanceDashboards({ commit }, params) {
            axios
                .post($themeConfig.url + 'getDashboard/balance_dashboards', params)
                .then((response) => {
                    commit('SET_BALANCE_DASHBOARD', response.data.result);
                    commit('SET_TOTAL_RECORDS', response.data.totalRecords);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchTransitionHistory({ commit }, params) {
            axios
                .post($themeConfig.url + 'getDashboards/payment', params)
                .then((response) => {
                    commit('SET_TRANSITION_HISTORY', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchDepositHistory({ commit }, params) {
            axios
                .post($themeConfig.url + 'getDashboards/deposit', params)
                .then((response) => {
                    commit('SET_DEPOSIT_HISTORY', response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchTopSaleDashboardAgency({ commit }, params) {
            axios
                .post($themeConfig.url + 'getDashboard/top_user_agents', params)
                .then((response) => {
                    commit('SET_TOPSALE_DASHBOARD_AGENCY', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchTopSaleDashboards({ commit }, params) {
            axios
                .post($themeConfig.url + 'getDashboard/top_agents', params)
                .then((response) => {
                    commit('SET_TOPSALE_DASHBOARD', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchPDDashboards({ commit }, params) {
            axios
                .post($themeConfig.url + 'getDashboard/total_pd_dashboards', params)
                .then((response) => {
                    commit('SET_PD_DASHBOARD', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async fetchBalance({ commit }) {
            axios.get($themeConfig.url + 'getBalance', {}).then((response) => {
                commit('SET_BALANCE', response.data.result);
            });
        },
        async fetchBalanceDashboard({ commit }) {
            axios.post($themeConfig.url + 'balance_dashboard', {}).then((response) => {
                commit('SET_BALANCE_DASHBOARD', response.data.result);
            });
        },
        async fetchAgencySaleProducts({ commit }, params) {
            await axios
                .post($themeConfig.url + 'getMasterData/agency_sale_product', params)
                .then((response) => {
                    commit('SET_AGENCY_SALE_PRODUCT', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async grantRoleForSaleProduct({ commit }, p) {
            await axios
                .put($themeConfig.url + 'masterData/agency_sale_product', p.params)
                .then((response) => {
                    p.vm.$msg('Cập nhật thành công');
                    p.vm.grantModal.hide();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        buy({ commit }, p, success, failure) {
            return axios
                .put($themeConfig.url + 'buy?notes=' + p.vm.notes + '&channel=backend', p.params)
                .then((response) => {
                    p.vm.orders = response.data.result;
                    p.vm.$msg(p.vm.$t('Buy success!'));
                    p.vm.$store.dispatch('fetchBalance', { vm: p.vm, params: p.vm.orders });
                    p.vm.editModal.show();
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Buy error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        buy1({ commit }, p, success, failure) {
            let orders_f = p.params?.orders[0];
            let orders = [{ amount: orders_f.amount, category_name: 'eSIM', code: orders_f.code, price: orders_f.price, plan_name: orders_f.description }];
            p.params.orders = orders;

            return axios
                .put($themeConfig.url_retail + 'createGGGOrder?notes=' + p.vm.notes + '&channel=backend', p.params)
                .then(async (response) => {
                    p.vm.orders = response.data.result;

                    //p.vm.$msg(p.vm.$t('Buy success!'));
                    await p.vm.$store.dispatch('fetchBalance', { vm: p.vm, params: p.vm.orders });
                    await p.vm.buyModal.hide();
                    await p.vm.payResultModal.show();
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Buy error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        //
        back_buy({ commit }, p) {
            return axios
                .put($themeConfig.url_retail + 'buy_esim_orders', p.params)
                .then(async (response) => {
                    p.vm.$msg('Update success');
                    p.vm.changeModal.hide();
                    p.vm.loadItem();
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Buy back error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        buy_now({ commit }, p, success, failure) {
            return axios
                .put($themeConfig.url_retail + 'buy_esims?notes=' + p.vm.notes + '&channel=backend', p.params)
                .then(async (response) => {
                    p.vm.orders = response.data.result;
                    //p.vm.$msg(p.vm.$t('Buy success!'));
                    await p.vm.$store.dispatch('fetchBalance', { vm: p.vm, params: p.vm.orders });
                    await p.vm.buyNowModal.hide();
                    await p.vm.orderConfirm.show();
                    console.log('responssss');
                    p.vm.dataCartConfirm = response.data.result.orders;
                    console.log(response.data.result.orders);
                    // await p.vm.payResultModal.show();
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Buy error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        buy12({ commit }, p, success, failure) {
            return axios
                .put($themeConfig.url_retail + 'buy_esims?notes=' + p.vm.notes + '&channel=backend', p.params)
                .then(async (response) => {
                    p.vm.orders = response.data.result;
                    // p.vm.$msg(p.vm.$t('Buy success!'));
                    // await p.vm.$store.dispatch('fetchBalance', { vm: p.vm, params: p.vm.orders });
                    p.vm.dataCartConfirm = JSON.parse(localStorage.getItem('ordersx'));
                    p.vm.notesConfirm = p.vm.notes;
                    p.vm.checkOutModal.hide();
                    await p.vm.orderConfirm.show();
                    localStorage.removeItem('ordersx');
                    p.vm.loadOrders();
                    p.vm.notes = null;
                    return response;
                })
                .catch((error) => {
                    p.vm.$msg(p.vm.$t('Buy error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        buy1_20230919({ commit }, p, success, failure) {
            return axios
                .put($themeConfig.url + 'buy?notes=' + p.vm.notes + '&channel=backend', p.params)
                .then(async (response) => {
                    p.vm.orders = response.data.result;
                    //p.vm.$msg(p.vm.$t('Buy success!'));
                    await p.vm.$store.dispatch('fetchBalance', { vm: p.vm, params: p.vm.orders });
                    await p.vm.buyModal.hide();
                    await p.vm.payResultModal.show();
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Buy error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        buy2({ commit }, p, success, failure) {
            return axios
                .put($themeConfig.url + 'buy?notes=' + p.vm.notes + '&channel=backend_import', p.params)
                .then(async (response) => {
                    p.vm.orders = response.data.result;
                    //p.vm.$msg(p.vm.$t('Buy success!'));
                    await p.vm.$store.dispatch('fetchBalance', { vm: p.vm, params: p.vm.orders });
                    await p.vm.buyModal.hide();
                    await p.vm.payResultModal.show();
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Buy error') + ' [' + error.response.data.message + ']', 'error');
                    return error;
                });
        },
        //helpdesk
        async fetchHelpdesk({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/helpdesks', params)
                .then((response) => {
                    response.data.result.forEach((i) => {
                        if (i.category_ids && i.category_ids != '') {
                            i.category_ids = JSON.parse(i.category_ids);
                        } else {
                            i.category_ids = [];
                        }
                    });
                    commit('SET_HELPDESKS', response.data.result);
                    commit('SET_TOTAL_RECORDS', response.data.totalRecords);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async newHelpdesk({ commit }, p) {
            axios
                .post($themeConfig.url + 'masterData/helpdesk', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới') + ': ' + error.response.data.message, 'error');
                });
        },
        async editHelpdesk({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/helpdesk', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật') + ': ' + error.response.data.message, 'error');
                });
        },
        async deleteFlag({ commit }, p) {
            axios
                .delete($themeConfig.url + 'masterData/rule_flag/' + p.params.id)
                .then((response) => {
                    p.vm.handleConfig();
                    p.vm.$msg(p.vm.$t('Xoá dữ liệu thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi xoá dữ liệu'), 'error');
                });
        },
        async deleteHelpdesk({ commit }, p) {
            axios
                .delete($themeConfig.url + 'masterData/helpdesks/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Xoá dữ liệu thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi xoá dữ liệu'), 'error');
                });
        },
        async testsmtp({ commit }, p) {
            axios
                .post($themeConfig.url + 'testsmtp', p.params)
                .then((response) => {
                    //p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Gửi test thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cấu hình SMTP') + ': ' + error.response.data.message, 'error');
                });
        },
        fetchHelpdeskTypes({ commit }, params) {
            //axios.post($themeConfig.url + 'getMasterData/helpdesk_products', params).then(response => {
            axios
                .post($themeConfig.url + 'getMasterData/helpdesk_category', params)
                .then((response) => {
                    commit('SET_HELPDESK_TYPES', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        //support ticket
        async fetchTickets({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/tickets', params)
                .then((response) => {
                    commit('SET_TICKETS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async newTicket({ commit }, p) {
            axios
                .post($themeConfig.url + 'masterData/ticket', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Thêm mới thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi tạo mới') + ': ' + error.response.data.message, 'error');
                });
        },
        async editTicket({ commit }, p) {
            axios
                .put($themeConfig.url + 'masterData/ticket', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật') + ': ' + error.response.data.message, 'error');
                });
        },
        async responseTicket({ commit }, p) {
            axios
                .put($themeConfig.url + 'responseTicket', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.editModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật'), 'error');
                });
        },
        async deleteTicket({ commit }, p) {
            axios
                .delete($themeConfig.url + 'masterData/support_tickets/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Xoá dữ liệu thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi xoá dữ liệu') + ': ' + error.response.data.message, 'error');
                });
        },
        async sendTicket({ commit }, p) {
            axios
                .put($themeConfig.url + 'sendSupportTicket/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Đã gửi phiếu hỗ trợ tới GIGAGO!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi gửi phiếu hỗ trợ') + ': ' + error.response.data.message, 'error');
                });
        },
        async closeTicket({ commit }, p) {
            axios
                .put($themeConfig.url + 'closeSupportTicket/' + p.params.id)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Đã đóng phiếu hỗ trợ tới GIGAGO!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi đóng phiếu hỗ trợ') + ': ' + error.response.data.message, 'error');
                });
        },
        async prioritySupportTicket({ commit }, p) {
            axios
                .put($themeConfig.url + 'prioritySupportTicket/' + p.params.id + '/' + p.params.priority)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.$msg(p.vm.$t('Đã cập nhật mức ưu tiên!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật mức ưu tiên') + ': ' + error.response.data.message, 'error');
                });
        },
        async commentTicket({ commit }, p) {
            axios
                .put($themeConfig.url + 'commentTicket', p.params)
                .then((response) => {
                    p.vm.loadItems();
                    p.vm.commentModal.hide();
                    p.vm.$msg(p.vm.$t('Cập nhật thành công!'));
                })
                .catch((error) => {
                    console.log(error);
                    p.vm.$msg(p.vm.$t('Lỗi cập nhật') + ': ' + error.response.data.message, 'error');
                });
        },
        async fetchComments({ commit }, params) {
            axios
                .post($themeConfig.url + 'getMasterData/ticketComments', { columnFilters: { support_ticket_id: params }, sort: [], page: 1, pageSize: 100 })
                .then((response) => {
                    commit('SET_TICKET_COMMENTS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchDepositBank({ commit }) {
            axios
                .get($themeConfig.url + 'deposit_banks')
                .then((response) => {
                    commit('SET_DEPOSIT_BANKS', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async createDepositAirwallex({ commit }, p) {
            axios
                .post($themeConfig.url + 'deposit_new', p.params)
                .then((response) => {
                    // p.vm.deposit_airwallex = response.data.result;
                    commit('SET_DEPOSIT_AIRWALLEX', response.data.result);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    modules: {},
};
