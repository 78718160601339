
export default [
    { 
        path: '/group-mgmt', name: 'group-mgmt', 
        component: () => import('../views/admin/Group.vue'),
        meta: {
            requiresAuth: true
        }
    },
    { 
        path: '/agency-mgmt', name: 'agency-mgmt', 
        component: () => import('../views/admin/Agency.vue'),
        meta: {
            requiresAuth: true
        }
    },
    { 
        path: '/user-mgmt', name: 'user-mgmt', 
        component: () => import('../views/admin/User.vue'),
        meta: {
            requiresAuth: true
        }
    },
    { 
        path: '/products', name: 'products', 
        component: () => import('../views/admin/MasterData.vue'),
        meta: {
            type:'products',
            requiresAuth: true
        }
    },
    { 
        path: '/currencies', name: 'currencies', 
        component: () => import('../views/admin/MasterData.vue'),
        meta: {
            type:'currencies',
            requiresAuth: true
        }
    },

    { 
        path: '/warehouses', name: 'warehouses', 
        component: () => import('../views/admin/MasterData.vue'),
        meta: {
            type:'warehouses',
            requiresAuth: true
        }
    },
    { 
        path: '/discount_agencies', name: 'discount-agencies', 
        component: () => import('../views/admin/AgencyDiscount.vue'),
        meta: {
            type:'discount_agencies',
            requiresAuth: true
        }
    },
    { 
        path: '/user/active/:session', name: 'active-user', 
        component: () => import('../views/admin/UserActive.vue'),
        meta: {
            type:'active_user',
        }
    },
    { 
        path: '/deposit_banks', name: 'deposit-banks', 
        component: () => import('../views/admin/MasterData.vue'),
        meta: {
            type:'md_deposit_banks',
            requiresAuth: true
        }
    },

];
