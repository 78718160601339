import store from './store';
import { $themeConfig } from '@themeConfig';

export default {
    init() {
        // set default styles
        let val = localStorage.getItem('dark_mode'); // light, dark, system
        if (!val) {
            val = $themeConfig.theme;
        }
        store.commit('toggleDarkMode', val);

        val = localStorage.getItem('menu_style'); // vertical, collapsible-vertical, horizontal
        if (!val) {
            val = $themeConfig.navigation;
        }
        store.commit('toggleMenuStyle', val);

        val = localStorage.getItem('layout_style'); // full, boxed-layout, large-boxed-layout
        if (!val) {
            val = $themeConfig.layout;
        }
        store.commit('toggleLayoutStyle', val);

        val = localStorage.getItem('default_menu'); // defaul-menu , home , sales, ...
        if (!val) {
            // val = $themeConfig.default_menu;
            val = 'Home';
        }
        store.commit('setDefaultMenu', val);

        val = localStorage.getItem('favourite_countries') ? JSON.parse(localStorage.getItem('favourite_countries').toString()) : null;
        if (!val) {
            // val = $themeConfig.favourite_countries;
            val = [];
        }
        store.commit('setFavouriteCountries', JSON.stringify(val));

        try {
            localStorage.removeItem('layout_location_grid');
            val =
                localStorage.getItem('layout_location_grid_new') && localStorage.getItem('layout_location_grid_new') != undefined
                    ? JSON.parse(localStorage.getItem('layout_location_grid_new').toString())
                    : null;
        } catch (e) {
            val = null;
        }
        if (val == null || val == undefined) {

            // val =  $themeConfig.layoutLocationGrid;
            val = {
                L_0: {
                    xxs: [
                        { x: 0, y: 0, w: 12, h: 13, i: '0', id: 'HOURS_SALES_REPORT', title: 'Hours sales report', customParamBox: null, display: true },
                        {
                            x: 0,
                            y: 13,
                            w: 12,
                            h: 14,
                            i: '1',
                            title: 'Daily sales reports',
                            id: 'DAILY_SALES_REPORT',
                            customParamBox: {
                                numberDay: 30,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 27,
                            w: 12,
                            h: 14,
                            i: '2',
                            title: 'Weekly sales reports',
                            id: 'WEEKLY_SALES_REPORT',
                            customParamBox: {
                                numberWeek: 32,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 41,
                            w: 12,
                            h: 13,
                            i: '3',
                            title: 'Monthly sales reports',
                            id: 'MONTHLY_SALES_REPORT',
                            customParamBox: {
                                numberMonth: 12,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 54,
                            w: 12,
                            h: 18,
                            i: '4',
                            title: 'Sales by Package',
                            id: 'SALES_BY_PACKAGE',
                            customParamBox: {
                                numberDay: 40,
                            },
                            display: true,
                        },
                        { x: 0, y: 72, w: 12, h: 16, i: '5', id: 'TOTAL_DEPOSIT', title: 'Total Deposit', customParamBox: null, display: true },
                        {
                            x: 0,
                            y: 88,
                            w: 12,
                            h: 12,
                            i: '6',
                            title: 'Top sales agents',
                            id: 'TOP_SALES_AGENTS',
                            customParamBox: {
                                numberDay: 45,
                            },
                            display: true,
                        },
                    ],
                    lg: [
                        { x: 0, y: 0, w: 12, h: 13, i: '0', id: 'HOURS_SALES_REPORT', title: 'Hours sales report', customParamBox: null, display: true },
                        {
                            x: 0,
                            y: 13,
                            w: 12,
                            h: 14,
                            i: '1',
                            title: 'Daily sales reports',
                            id: 'DAILY_SALES_REPORT',
                            customParamBox: {
                                numberDay: 30,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 27,
                            w: 6,
                            h: 13,
                            i: '2',
                            title: 'Weekly sales reports',
                            id: 'WEEKLY_SALES_REPORT',
                            customParamBox: {
                                numberWeek: 32,
                            },
                            display: true,
                        },
                        {
                            x: 6,
                            y: 27,
                            w: 6,
                            h: 13,
                            i: '3',
                            title: 'Monthly sales reports',
                            id: 'MONTHLY_SALES_REPORT',
                            customParamBox: {
                                numberMonth: 12,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 40,
                            w: 6,
                            h: 17,
                            i: '4',
                            title: 'Sales by Package',
                            id: 'SALES_BY_PACKAGE',
                            customParamBox: {
                                numberDay: 40,
                            },
                            display: true,
                        },
                        { x: 6, y: 40, w: 6, h: 17, i: '5', id: 'TOTAL_DEPOSIT', title: 'Total Deposit', customParamBox: null, display: true },
                        {
                            x: 0,
                            y: 57,
                            w: 12,
                            h: 12,
                            i: '6',
                            title: 'Top sales agents',
                            id: 'TOP_SALES_AGENTS',
                            customParamBox: {
                                numberDay: 45,
                            },
                            display: true,
                        },
                    ],
                },
                L_1: {
                    xxs: [
                        {
                            x: 0,
                            y: 0,
                            w: 12,
                            h: 14,
                            i: '1',
                            title: 'Daily sales reports',
                            id: 'DAILY_SALES_REPORT',
                            customParamBox: {
                                numberDay: 30,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 14,
                            w: 12,
                            h: 14,
                            i: '2',
                            title: 'Weekly sales reports',
                            id: 'WEEKLY_SALES_REPORT',
                            customParamBox: {
                                numberWeek: 32,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 28,
                            w: 12,
                            h: 13,
                            i: '3',
                            title: 'Monthly sales reports',
                            id: 'MONTHLY_SALES_REPORT',
                            customParamBox: {
                                numberMonth: 12,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 41,
                            w: 12,
                            h: 18,
                            i: '4',
                            title: 'Sales by Package',
                            id: 'SALES_BY_PACKAGE',
                            customParamBox: {
                                numberDay: 40,
                            },
                            display: true,
                        },
                    ],
                    lg: [
                        {
                            x: 0,
                            y: 0,
                            w: 6,
                            h: 13,
                            i: '1',
                            title: 'Daily sales reports',
                            id: 'DAILY_SALES_REPORT',
                            customParamBox: {
                                numberDay: 30,
                            },
                            display: true,
                        },
                        {
                            x: 6,
                            y: 0,
                            w: 6,
                            h: 13,
                            i: '2',
                            title: 'Weekly sales reports',
                            id: 'WEEKLY_SALES_REPORT',
                            customParamBox: {
                                numberWeek: 32,
                            },
                            display: true,
                        },
                        {
                            x: 0,
                            y: 13,
                            w: 6,
                            h: 13,
                            i: '3',
                            title: 'Monthly sales reports',
                            id: 'MONTHLY_SALES_REPORT',
                            customParamBox: {
                                numberMonth: 12,
                            },
                            display: true,
                        },
                        {
                            x: 6,
                            y: 13,
                            w: 6,
                            h: 13,
                            i: '4',
                            title: 'Sales by Package',
                            id: 'SALES_BY_PACKAGE',
                            customParamBox: {
                                numberDay: 40,
                            },
                            display: true,
                        },
                    ],
                },
            }
            
        }
        store.commit('setLayoutLocationGrid', JSON.stringify(val));

        val = localStorage.getItem('i18n_locale'); // en, da, de, el, es, fr, hu, it, ja, pl, pt, ru, sv, tr, zh
        if (!val) {
            val = $themeConfig.lang;
            const list = store.state.countryList;
            const item = list.find((item) => item.code === val);
            if (item) {
                this.toggleLanguage(item);
            }
        }
    },

    toggleLanguage(item) {
        let lang = null;
        if (item) {
            lang = item;
        } else {
            let code = store.state.locale;
            if (!code) {
                code = localStorage.getItem('i18n_locale');
            }

            item = store.state.countryList.find((d) => d.code === code);
            if (item) {
                lang = item;
            }
        }

        if (!lang) {
            lang = store.state.countryList.find((d) => d.code === 'en');
        }

        store.commit('toggleLocale', lang.code);
        return lang;
    },

    toggleMode(mode) {
        if (!mode) {
            let val = localStorage.getItem('dark_mode'); //light|dark|system
            mode = val;
            if (!val) {
                mode = 'light';
            }
        }
        store.commit('toggleDarkMode', mode || 'light');
        return mode;
    },
};
