import { createStore } from 'vuex';
import i18n from '../i18n';
import md from './modules/master-data';
import esim from './modules/esim';

export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        default_menu: 'Home',
        default_type_eSimPlan: 'list',
        layout_location_grid: {
            xxs: [
                { x: 0, y: 0, w: 12, h: 13, i: '0', title: 'Hours sales report' },
                { x: 0, y: 13, w: 12, h: 14, i: '1', title: 'Daily sales reports' },
                { x: 0, y: 27, w: 12, h: 14, i: '2', title: 'Weekly sales reports' },
                { x: 0, y: 41, w: 12, h: 13, i: '3', title: 'Monthly sales reports' },
                { x: 0, y: 54, w: 12, h: 18, i: '4', title: 'Sales by Package' },
                { x: 0, y: 72, w: 12, h: 16, i: '5', title: 'Total Deposit' },
                { x: 0, y: 88, w: 12, h: 12, i: '6', title: 'Top sales agents' },
            ],
            lg: [
                { x: 0, y: 0, w: 12, h: 13, i: '0', title: 'Hours sales report' },
                { x: 0, y: 13, w: 12, h: 14, i: '1', title: 'Daily sales reports' },
                { x: 0, y: 27, w: 6, h: 13, i: '2', title: 'Weekly sales reports' },
                { x: 6, y: 27, w: 6, h: 13, i: '3', title: 'Monthly sales reports' },
                { x: 0, y: 40, w: 6, h: 17, i: '4', title: 'Sales by Package' },
                { x: 6, y: 40, w: 6, h: 17, i: '5', title: 'Total Deposit' },
                { x: 0, y: 57, w: 12, h: 12, i: '6', title: 'Top sales agents' },
            ],
        },
        favourite_countries: [],
        countryList: [
            { code: 'vi', name: 'Vietnamese' },
            { code: 'en', name: 'English' },
        ],
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        setDefaultMenu(state, payload) {
            localStorage.setItem('default_menu', payload);
            state.default_menu = payload;
        },
        setDefaultTypeEsimPlan(state, payload) {
            localStorage.setItem('default_type_eSimPlan', payload);
            state.default_type_eSimPlan = payload;
        },
        setFavouriteCountries(state, payload) {
            localStorage.setItem('favourite_countries', payload);
            state.favourite_countries = payload;
        },
        setLayoutLocationGrid(state, payload) {
            localStorage.setItem('layout_location_grid_new', payload);
            state.layout_location_grid = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            console.log(value);
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            console.log(value);
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
    },
    actions: {
        hideSlideBar({ commit }) {
            commit('toggleSideBar', false);
        },
    },
    modules: {
        md,
        esim,
    },
});
