export default [
    
    //dashboard
    {
        path: '/',
        name: 'Home',   
        component: () => (localStorage.getItem('userData') ? (JSON.parse(localStorage.getItem('userData')).agency.agency_level != 0 ) : true) ? import('../views/esim/DashboardAgentNew.vue') : import('../views/esim/Dashboard.vue') ,
        meta: {
            requiresAuth: true,
        },
    },

    //pages

    {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },

    //users
    {
        path: '/users/profile',
        name: 'profiles',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/profile.vue'),
    },
    {
        path: '/users/account-setting',
        name: 'account-settings',
        component: () => import(/* webpackChunkName: "users-account-setting" */ '../views/users/account_setting.vue'),
    },
];
